// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-employee-js": () => import("./../src/pages/employee.js" /* webpackChunkName: "component---src-pages-employee-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tags-js": () => import("./../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-what-we-do-enterprise-app-development-js": () => import("./../src/pages/what-we-do/enterprise-app-development.js" /* webpackChunkName: "component---src-pages-what-we-do-enterprise-app-development-js" */),
  "component---src-pages-what-we-do-quality-assurance-js": () => import("./../src/pages/what-we-do/quality-assurance.js" /* webpackChunkName: "component---src-pages-what-we-do-quality-assurance-js" */),
  "component---src-pages-what-we-do-solutions-and-systems-js": () => import("./../src/pages/what-we-do/solutions-and-systems.js" /* webpackChunkName: "component---src-pages-what-we-do-solutions-and-systems-js" */),
  "component---src-templates-blog-post-blog-post-js": () => import("./../src/templates/blog-post/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-blog-post-js" */),
  "component---src-templates-blog-tags-blog-tags-js": () => import("./../src/templates/blog-tags/blog-tags.js" /* webpackChunkName: "component---src-templates-blog-tags-blog-tags-js" */)
}

