import React, { memo, useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';
import '../landing-animation.scss';
import AnimationPath from '../animation-path';

const AnimationPathWorldEnvDay = () => {
  useEffect(() => {
    let pathEls = document.querySelectorAll('path.on-ee');
    for (let i = 0; i < pathEls.length; i++) {
      let pathEl = pathEls[i];
      let offset = anime.setDashoffset(pathEl);
      pathEl.setAttribute('stroke-dashoffset', offset);
      let anim = anime({
        targets: pathEl,
        strokeDashoffset: [offset, 0],
        duration: anime.random(5000, 9000),
        delay: anime.random(200, 200),
        loop: true,
        direction: 'alternate',
        easing: 'easeInOutBack',
        translateX: [5, -2],
        translateY: [-2, 5],
        autoplay: true,
      });
      anim.play();
    }
  }, []);

  return (
    <div>
    
    <AnimationPath/>
    <div className="anim">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMinYMin slice "
        fill="var(--bg-color)"
        viewBox="0 0 1920 1080"
        width="100%"
      >
        <path className="on-ee" 
          stroke="var(--primary-color)"
          d="M1350.1,185.1c13.6-36.6,51.2-62.8,90.3-62.3c-1.1,1.7-3.3,1.3-4.9,2c-1.9,2.1-0.2,4.6,1.6,6
				c-0.7,0.3-1.3,0.5-2,0.8c-1.4,3.1-3.6,5.9-6.4,7.9c1.5,1.6,5.1,3.9,6.5,1.1c1-2.6,4.5-3.2,4.7-6.3c3.1-0.9,6.2-2.5,7.7-5.6
				c1-0.2,2-0.4,3-0.6c1-4.4-5-4.9-8-5.5c30-0.2,59.4,14.9,77.3,38.8c0.2,0.3,0.3,0.6,0.4,1c0.4,2.1,2,3.6,3.2,5.3
				c-1.5-0.8-2.9-1.7-4.3-2.6c0.7,0.8,1.4,1.6,2.1,2.4c-1.7-0.8-3.5-1.1-5.4-0.7c-0.7-0.6-1.5-1.2-2.2-1.8c0.5,0.9,1,1.7,1.6,2.5
				c-0.7,0.8-1.6,1.9-2.8,1.8c-1.5-1.1-2.4-2.8-3.5-4.2c1.4,0.8,2.8,1.4,4.3,1.9c-1.8-3.1-4.9-5.1-7.8-7.1c-1.2-0.6-2.5-1.7-3.9-1.3
				c-2.7,2.8-4.1,6.5-6.1,9.7c-1,1.5-1.2,3.9,0.6,4.9c1.2,0.1,2.3-0.5,3.5-0.5c1.8,0.7,2.4,2.8,3.4,4.3c0.3,0.1,0.8,0.2,1,0.3
				c-0.2-1.8-0.1-3.7,0.2-5.4c-1.5-1.4-2.5-3.4-1.1-5.3c0.4-1.8,2.9-2.3,3.2-0.2c-0.9,0.7-2.2,1.4-1.8,2.7c0.6,2.5,3.5,3.2,5.3,4.8
				c-1,0.4-2.1,0.7-3.1,1c-0.5,2.2,2,3.1,3.1,4.7c-2.5-0.6-5.1-0.7-7.7-0.9c-1.3-0.1-0.8-1.6-1-2.4c-0.5-0.8-1.3-1.2-2-1.7
				c0.5,3.2-2.4,4.7-3.9,7c-0.8,1.2-2.4,1.3-3.6,1.8c-0.1,2.2,2.2,3.5,2.3,5.7c-1.5-0.2-3-0.3-4.5-0.3c-0.4,1.9-1.1,3.7-0.8,5.7
				c0.8,2,3.5,2.9,5.5,2.1c1.4-1.6,2.2-3.7,3.6-5.3c1-1.8,3.9-2.4,5.4-0.9c2.1,1.7,3.9,3.7,5.8,5.7c0.4-3.6-3.8-4.7-4.8-7.7
				c2.5,1.1,3.8,3.7,6.3,5c2.1,1.1,2.3,3.7,4.2,5c-0.2-1.1-0.7-2.1-1.2-3.1c0.5-0.1,0.9-0.2,1.4-0.3c1.7,3.8,5.6,5.5,9.6,5
				c0,1.9-1,3.9-2.9,4.4c-3.5,1.1-6-2.8-9.5-2.3c-1.5-0.1-1.1,1.8-1.3,2.7c-2.2-3-6.3-4-7.4-7.8c-3.3-1.4-6.8,0.9-10.1,1.2
				c-3.3,0.1-3.4,4.2-5.3,6.1c-1.9,1.8-4.1,3.5-4.9,6.1c-1,2.1,0.8,4.2,0.5,6.3c-0.4,1.2-1,2.3-1.6,3.4c2.5,2.9,4.8,6.6,8.6,7.9
				c3.7,1.3,7.1-2,10.8-1.1c1.5,0.3,2.9,1.1,4,2.2c-0.2,2.2-1.9,4.8,0,6.6c1.2,1.4,2.6,2.9,2.7,4.9c0.7,3.4-4.3,5.4-2.4,8.8
				c1.9,3.4,0.1,7.3,0.9,10.9c0.2,1.3,1.8,2.4,3,1.5c2.6-1.9,6.2-2.4,8-5.2c1.6-2.5,4.2-4.4,4.9-7.4c0.7-3.1,4.2-4.2,5.3-7.1
				c1.6-3.3-0.3-7.8,2.8-10.4c3-2.6,7.6-4.9,7.1-9.6c-2.2-0.9-5.4,1.2-6.8-1.4c-2.2-4.8-5.6-8.9-7.3-13.9c3,1.4,4,4.8,6.2,7
				c2,1.9,2,4.7,2.8,7.2c2.7-0.9,5-2.5,7.7-3.5c0.9,25-8.2,50.1-25,68.6c-20.2,22.8-51.7,35.6-82,32.1c-1.2-2.3-4-4.1-3.1-7
				c-0.1-3.4-0.4-6.7,0.6-10c1.1-0.5,1.8-1.5,2.2-2.7c0.9-0.3,1.7-0.7,2.6-0.9c0-1.1,0-2.3-0.1-3.4c3.5-1.4,5-5.8,6.5-9.1
				c0.2-3.4,4-3.5,6.1-5.4c2.2-2.1,1.6-5.4,2.7-7.9c1.1-2.7,4.5-3.7,5.4-6.4c0.4-2.6-1.8-4.5-3.9-5.6c-2.8-2-6.4-1.4-9.4-2.9
				c-3.2-1.6-3.1-6.5-6.9-7.6c-3-0.8-5.7-2.3-7.9-4.4c-2.2-2.3-5.8-0.9-8.3-2.7c-2.5-1.8-6.7-0.9-6.3,2.8c-2.4,0.2-5.1-0.7-6.2-3
				c-1.2-2.4-3.2-4.5-5.9-5.2c-0.3-2.2,0.1-4.4,0.1-6.5c-2.4,0.3-3.6,3.9-6.3,2.7c-3.9-1.7-5-7.4-3.1-10.9c2.6-3.3,7.1,0.4,10.1-2
				c3.8-0.6,4.5,3.9,7,5.6c0.7-2.6-0.2-5.3,0.6-7.8c1.2-1.4,3.2-1.8,4.4-3.3c1.6-2.3,1.5-5.5,3.3-7.7c1.2-1.1,2.9-1.5,4.3-2.3
				c-1.4-3.1,1.9-5.1,4.7-4.3c0.1,0.6,0.3,1.1,0.4,1.7c1.5-0.3,3.3-0.5,4.3-1.8c0.7-1.4-1.3-2-2.2-2.7c-0.2-1-0.4-1.9-0.5-2.9
				c-0.9-0.4-1.7-0.8-2.6-1.2c2.4-2.3,5.9-0.5,8.7-0.7c2.4,0.2,4.6-1.9,4.3-4.3c-0.2-2.9-3.2-4.4-4.3-6.8c-0.4-1.7-0.4-3.5-0.7-5.2
				c-1.6,1-3.5,2.8-5.4,1.7c-1.1-1.6-0.4-3.8-1.4-5.5c-0.9-1.4-3.2-1.2-4.1,0c-0.1,1.9-0.6,3.8-2.2,5.1c0.3,2.2,0.4,4.6-1.8,5.9
				c-3,1.7,0.1,8.1-4.7,7.3c-0.2-2.3,0.8-4.5,0.3-6.7c-2-2.7-6.3-3.1-7.6-6.4c0.5-3.8,3.3-7.1,7-8.2c3.1-0.9,3.3-5,6.3-6.1
				c2.6-1.1,5-4.3,4.3-7.1c-0.6-0.1-1.2-0.1-1.8-0.2c-0.8,1.2-0.6,4.5-2.4,4c-1.1-3.4-1.7-6.9-0.8-10.4c-1.7,0.7-3.6,1.9-3.9,3.8
				c-0.3,1.6,1.1,2.7,1.9,3.9c-1,0.9-2.2,0.4-3.3,0.1c-0.8,0.6-1.5,1.3-2.3,2c-2.1,0-3.2-2-4.7-3c-1.7-0.2-1.5,2.4-2.1,3.5
				c-2.4,0.5-4.1-2-4.5-4.1c-0.3-1.9-2.4-2-4-2.4c-0.2-0.5-0.4-1-0.6-1.5c-2.4,0.7-4.5,2-6.7,3.1c-0.6-3.2-4.5-2.8-6-5.2
				c-3.5,1.1-5.9,4.2-9,6c-2.9,1.3-1.6,6.3-5.1,6.4c-2-0.1-3.5,1.6-3.7,3.5c0.8,0,1.6,0,2.4,0c-3,2.9-7,4.8-9.2,8.5
				c-1.1,1.7,1.3,3.8-0.3,5.2C1355.9,182,1352.9,183.5,1350.1,185.1z M1458.1,128.8c0.3,1.4,0.6,2.7,0.8,4.1c-1.3-0.7-2-2.1-2.7-3.3
				c-0.2,0.6-0.5,1.1-0.7,1.7c-0.3-0.6-0.7-1.2-1-1.7c-0.3,0.7-0.6,1.4-0.9,2.1c0-0.9,0.1-1.7,0.2-2.6c-0.8,0-1.6,0-2.4,0
				c0,0.8-0.1,1.5-0.1,2.3c-1-0.1-0.9-1.1-1.1-1.8c-1.1,1.6-3.1,1.9-4.4,3.2c0.4,0.5,0.9,1,1.3,1.5c0,0.3,0,0.8-0.1,1
				c-2,0.7-4.2,0.8-6.3,1.1c-0.2,1.6,1.1,2.6,2.1,3.6c-0.9,0-1.8,0.1-2.6,0.1c0.2,1,0.5,2,1,2.9c2,0.5,4.8,0,5.8,2.3
				c0.3,3.2,0.4,6.5-0.8,9.5c1.3,0.1,2.6,0.5,2.8,2c-0.6,0-1.2,0-1.8,0c-0.4,0.9-0.7,1.8-0.9,2.7c0.8,0.1,1.6,0.2,2.5,0.3
				c0.2,2.1-2.6,2.8-3.2,4.6c-1.6,4,0.3,8.3,2.3,11.8c0.4,0.9,1.8,1.5,2.5,0.7c1.9-2.1,2.9-4.8,4.2-7.3c1.1-2.5,4.9-0.7,6.2-3.1
				c1.7-2.5,4.9-2.5,7.6-2.7c0-1.9-2.2-2.8-2.2-4.6c1.5,0.2,1.7,2.3,3.4,2.1c-0.3-2-1.2-3.8-1.4-5.8c0.7,0.1,1.4,0.3,2.1,0.4
				c0-0.5,0-1,0-1.5c2.2-1.5,0.6-4.1,0.1-6c0.6,0,1.1,0,1.7,0.1c-2.4-2.7,1.1-6.3-1.4-8.7c1.4,0.2,4.2-0.2,3.7-2
				c-2-1.7-4.6-0.2-6.8-0.2c0.8-0.6,1.6-1.1,2.4-1.6c-2.1-1.1-4-2.5-6.4-2.6c0.1-0.3,0.3-1,0.4-1.3c1.9,0,3.6,0.9,5.5,1.3
				c-0.8-3.2-5.1-2.6-6.4-4.3c1.4,0.2,2.9,0.5,4.3,0.8C1464.7,127.5,1461,126.9,1458.1,128.8z M1422.2,151.4
				c-0.2,1.1-1.4,2.7-0.2,3.6c2.1,1.2,4.9,0.4,6.8,2.1c1.1,1,2.7,2.5,2,4.1c-1.3,2.7-3.8,4.8-6.8,5.3c0,0.3,0,0.9,0,1.2
				c3.4-0.6,5.3,2.3,6.1,5.2c0.7,0,1.4-0.1,2.1-0.4c0-1-0.1-2.1-0.1-3.1c1,0.4,1,2.8,2.3,1.9c1.4-1.8-1.2-3.4-1.4-5.2
				c1.5,0.2,3,0.8,4.6,0.9c0.7-1.7,0.7-3.9-0.6-5.3c-2-2,0-5.3-1.9-7.4c-1.2-1.8-3.2-2.6-5.2-3.2c0.9-0.3,1.8-0.4,2.8-0.4
				c-0.1-2.3-2.9-3.2-4.8-2.5c-1.6,1.1-2.2,3.1-3.8,4.1c-0.4-2.1,1.3-3.7,2.3-5.3C1423.7,146.7,1422.7,149.3,1422.2,151.4z
				 M1505.7,196.8c0.3,1.1,1.6,1.2,2.5,1.5c0-0.5,0-1,0-1.5C1507.3,196.8,1506.5,196.7,1505.7,196.8z M1403.6,229.7
				c2.9-0.5,6.3,0.9,8,3.4c0.9,0.1,1.8,0.1,2.7,0.1c0.1-2.5-2.7-2.5-4.3-3.7C1408.1,228.1,1405.5,228.4,1403.6,229.7z M1415.8,232.4
				c0,0.5-0.1,1-0.1,1.5c-0.5,0.3-0.9,0.6-1.3,1c1.7-0.2,5.3,1.6,5.5-1C1418.4,233.4,1417.1,232.9,1415.8,232.4z"
        />

        <path className="on-ee" 
          stroke="var(--primary-color)"
          d="M1369.8,172.9c2.2,1.3,2.1,3.9,2.2,6.2c0,2.1,2,3.3,3.1,4.9c-1,3-0.3,6.2-1.3,9.2c1,5.1-3.7,8.7-3.6,13.6
			c-0.4,4.5,2.6,8.2,4.4,12c1.3,3.2,2.5,6.5,4.9,9c1.3-4-2.9-6.4-3.1-10c1.9-0.1,2.2,2,2.8,3.4c0.8,2.6,2.8,4.7,3.7,7.3
			c0.9,2.3,1.5,5.1,3.8,6.5c2.8,1.4,6.4,0.7,8.9,3.1c2.6,2.5,6.6,3.1,8.4,6.5c1.5,3,6.4,0.8,7.5,4.1c0.8,3.6-3.9,5.6-3.5,9.2
			c0.5,4.5,4.3,7.5,6,11.5c1,2.9,4.6,3.5,5.8,6.2c1.4,2.9,1.2,6.2,1.8,9.2c1.2,6.2-1.2,12.5,0.3,18.7c0.7-0.7,1.4-1.3,2.2-1.8
			c-0.1,2.3-1.3,4.5-1.2,6.8c0.9,3.8,3,7.4,6.1,9.8c-24.6-2.6-47.7-15.9-63.4-35c-22.1-26.5-28.5-65-15.8-97.1
			c4.3-1.6,8.2-4.1,11.5-7.3c1.4-1.3,2.1-3.3,3.9-4c-0.1,0.7-0.2,1.3-0.3,2C1366.7,175.7,1368.3,174.3,1369.8,172.9z"
        />
        <path className="on-ee" 
          stroke="var(--primary-color)"
          d="M1458.1,128.8c2.9-1.8,6.6-1.3,8.9,1.1c-1.4-0.3-2.9-0.6-4.3-0.8c1.4,1.8,5.7,1.1,6.4,4.3
			c-1.8-0.4-3.6-1.3-5.5-1.3c-0.1,0.3-0.3,1-0.4,1.3c2.5,0.1,4.3,1.5,6.4,2.6c-0.8,0.5-1.6,1.1-2.4,1.6c2.2-0.1,4.9-1.6,6.8,0.2
			c0.5,1.8-2.3,2.3-3.7,2c2.5,2.4-1,6,1.4,8.7c-0.6,0-1.1,0-1.7-0.1c0.5,1.9,2.1,4.5-0.1,6c0,0.5,0,1,0,1.5
			c-0.7-0.1-1.4-0.3-2.1-0.4c0.2,2,1.1,3.8,1.4,5.8c-1.7,0.2-1.9-1.9-3.4-2.1c0,1.8,2.2,2.7,2.2,4.6c-2.7,0.2-5.9,0.2-7.6,2.7
			c-1.3,2.4-5.1,0.6-6.2,3.1c-1.3,2.5-2.4,5.2-4.2,7.3c-0.8,0.9-2.1,0.3-2.5-0.7c-2-3.5-3.8-7.8-2.3-11.8c0.6-1.9,3.4-2.5,3.2-4.6
			c-0.8-0.1-1.6-0.2-2.5-0.3c0.2-0.9,0.5-1.8,0.9-2.7c0.6,0,1.2,0,1.8,0c-0.1-1.5-1.5-1.9-2.8-2c1.2-3,1.1-6.3,0.8-9.5
			c-1-2.2-3.8-1.8-5.8-2.3c-0.5-0.9-0.9-1.9-1-2.9c0.9-0.1,1.8-0.1,2.6-0.1c-1-1-2.3-2.1-2.1-3.6c2.1-0.3,4.2-0.4,6.3-1.1
			c0-0.3,0-0.8,0.1-1c-0.4-0.5-0.9-1-1.3-1.5c1.3-1.3,3.3-1.6,4.4-3.2c0.2,0.7,0.1,1.7,1.1,1.8c0.1-0.8,0.1-1.5,0.1-2.3
			c0.8,0,1.6,0,2.4,0c-0.1,0.9-0.1,1.7-0.2,2.6c0.3-0.7,0.6-1.4,0.9-2.1c0.3,0.6,0.7,1.2,1,1.7c0.2-0.6,0.5-1.1,0.7-1.7
			c0.7,1.2,1.4,2.6,2.7,3.3C1458.8,131.5,1458.5,130.1,1458.1,128.8z"
        />
        <path className="on-ee" 
          stroke="var(--primary-color)"
          d="M1422.2,151.4c0.5-2.1,1.5-4.6,4.1-4.6c-1,1.7-2.6,3.2-2.3,5.3c1.7-1,2.3-3,3.8-4.1c1.9-0.7,4.7,0.2,4.8,2.5
			c-0.9,0.1-1.9,0.1-2.8,0.4c2,0.6,4,1.4,5.2,3.2c1.9,2.1-0.1,5.4,1.9,7.4c1.3,1.4,1.4,3.6,0.6,5.3c-1.6,0-3-0.7-4.6-0.9
			c0.2,1.8,2.8,3.3,1.4,5.2c-1.3,0.9-1.3-1.5-2.3-1.9c0,1,0,2.1,0.1,3.1c-0.7,0.3-1.4,0.4-2.1,0.4c-0.8-2.9-2.7-5.8-6.1-5.2
			c0-0.3,0-0.9,0-1.2c3-0.5,5.5-2.6,6.8-5.3c0.7-1.7-0.9-3.1-2-4.1c-1.9-1.7-4.7-0.9-6.8-2.1C1420.8,154.1,1422,152.5,1422.2,151.4z
			"
        />
        <path className="on-ee" 
          stroke="var(--primary-color)"
          d="M1374.1,156c3.1-1.8,5.5-4.9,9-6c1.6,2.4,5.5,2,6,5.2c2.2-1.1,4.3-2.4,6.7-3.1c0.2,0.5,0.4,1,0.6,1.5
			c1.5,0.4,3.7,0.5,4,2.4c0.4,2.1,2.1,4.6,4.5,4.1c0.5-1.1,0.4-3.7,2.1-3.5c1.6,1.1,2.6,3.1,4.7,3c0.7-0.7,1.5-1.3,2.3-2
			c1.1,0.3,2.4,0.7,3.3-0.1c-0.8-1.2-2.1-2.3-1.9-3.9c0.3-2,2.2-3.2,3.9-3.8c-1,3.5-0.3,7,0.8,10.4c1.8,0.4,1.6-2.8,2.4-4
			c0.6,0.1,1.2,0.1,1.8,0.2c0.6,2.9-1.7,6-4.3,7.1c-3,1.1-3.2,5.1-6.3,6.1c-3.7,1.1-6.5,4.4-7,8.2c1.4,3.2,5.6,3.7,7.6,6.4
			c0.5,2.2-0.6,4.5-0.3,6.7c4.8,0.8,1.8-5.6,4.7-7.3c2.1-1.3,2.1-3.7,1.8-5.9c1.6-1.2,2.1-3.1,2.2-5.1c0.9-1.2,3.2-1.4,4.1,0
			c1,1.6,0.4,3.9,1.4,5.5c1.9,1.2,3.8-0.7,5.4-1.7c0.3,1.7,0.3,3.5,0.7,5.2c1.1,2.5,4.1,3.9,4.3,6.8c0.3,2.4-2,4.5-4.3,4.3
			c-2.9,0.2-6.4-1.6-8.7,0.7c0.8,0.4,1.7,0.8,2.6,1.2c0.2,1,0.4,1.9,0.5,2.9c0.9,0.7,2.9,1.2,2.2,2.7c-1.1,1.3-2.8,1.5-4.3,1.8
			c-0.1-0.6-0.3-1.1-0.4-1.7c-2.7-0.9-6,1.2-4.7,4.3c-1.4,0.8-3.1,1.2-4.3,2.3c-1.8,2.2-1.7,5.4-3.3,7.7c-1.2,1.5-3.2,1.9-4.4,3.3
			c-0.8,2.5,0.2,5.3-0.6,7.8c-2.5-1.7-3.2-6.1-7-5.6c-3.1,2.4-7.5-1.2-10.1,2c-1.9,3.5-0.8,9.2,3.1,10.9c2.7,1.2,3.9-2.3,6.3-2.7
			c0,2.2-0.4,4.4-0.1,6.5c2.7,0.7,4.7,2.7,5.9,5.2c1.1,2.3,3.8,3.2,6.2,3c-0.4-3.6,3.9-4.5,6.3-2.8c2.5,1.8,6.1,0.4,8.3,2.7
			c2.3,2.1,5,3.6,7.9,4.4c3.7,1,3.6,5.9,6.9,7.6c2.9,1.6,6.6,1,9.4,2.9c2.1,1.1,4.2,3,3.9,5.6c-1,2.8-4.3,3.7-5.4,6.4
			c-1.1,2.6-0.6,5.9-2.7,7.9c-2.1,1.8-5.9,2-6.1,5.4c-1.5,3.3-3,7.6-6.5,9.1c0.1,1.1,0.1,2.3,0.1,3.4c-0.9,0.3-1.8,0.6-2.6,0.9
			c-0.4,1.1-1,2.2-2.2,2.7c-1,3.3-0.7,6.7-0.6,10c-0.9,2.9,1.8,4.8,3.1,7c-1.3,0.1-2.7,0.1-3.9-0.4c-3.1-2.4-5.2-6-6.1-9.8
			c-0.1-2.3,1.1-4.5,1.2-6.8c-0.9,0.4-1.6,1.1-2.2,1.8c-1.6-6.1,0.9-12.5-0.3-18.7c-0.6-3.1-0.4-6.4-1.8-9.2
			c-1.3-2.7-4.9-3.2-5.8-6.2c-1.7-4-5.5-7-6-11.5c-0.4-3.6,4.3-5.6,3.5-9.2c-1.1-3.4-5.9-1.1-7.5-4.1c-1.8-3.4-5.9-3.9-8.4-6.5
			c-2.4-2.3-6-1.7-8.9-3.1c-2.3-1.4-3-4.1-3.8-6.5c-0.9-2.6-2.8-4.7-3.7-7.3c-0.5-1.4-0.8-3.4-2.8-3.4c0.2,3.6,4.4,6,3.1,10
			c-2.4-2.5-3.7-5.8-4.9-9c-1.8-3.9-4.8-7.5-4.4-12c-0.1-4.9,4.7-8.6,3.6-13.6c1-3,0.3-6.2,1.3-9.2c-1.2-1.5-3.1-2.8-3.1-4.9
			c-0.1-2.2,0-4.9-2.2-6.2c-1.6,1.4-3.2,2.7-5,3.8c0.1-0.7,0.2-1.3,0.3-2c-1.8,0.7-2.5,2.8-3.9,4c-3.3,3.2-7.2,5.7-11.5,7.3
			c0.1-0.2,0.3-0.7,0.4-1c2.8-1.7,5.8-3.2,8.1-5.5c1.6-1.4-0.8-3.5,0.3-5.2c2.1-3.7,6.1-5.7,9.2-8.5c-0.8,0-1.6,0-2.4,0
			c0.2-1.9,1.7-3.6,3.7-3.5C1372.5,162.3,1371.3,157.3,1374.1,156z"
        />

        <path className="on-ee" 
          stroke="var(--primary-color)"
          d="M1501.7,158.9c1.4-0.4,2.7,0.7,3.9,1.3c2.9,1.9,6,4,7.8,7.1c-1.5-0.5-2.9-1.2-4.3-1.9c1.1,1.5,2,3.1,3.5,4.2
				c1.2,0.1,2.1-1,2.8-1.8c-0.5-0.8-1.1-1.7-1.6-2.5c0.7,0.6,1.5,1.2,2.2,1.8c1.8-0.5,3.7-0.1,5.4,0.7c-0.7-0.8-1.4-1.6-2.1-2.4
				c1.5,0.8,2.9,1.7,4.3,2.6c-1.2-1.7-2.8-3.2-3.2-5.3c0.9,0.9,1.8,1.9,2.5,3c8.9,13.1,14.7,28.4,16.4,44.1c-1.9-0.3-3.4-1.6-5-2.5
				c0.6,2.5,2.6,4.3,5.1,4.5c0.3,2.1,0.6,4.1,0.5,6.2c-2.6,1-5,2.7-7.7,3.5c-0.8-2.5-0.8-5.3-2.8-7.2c-2.2-2.3-3.2-5.6-6.2-7
				c1.7,5,5.1,9.2,7.3,13.9c1.3,2.6,4.6,0.5,6.8,1.4c0.4,4.7-4.1,7-7.1,9.6c-3.1,2.6-1.2,7.1-2.8,10.4c-1,2.9-4.6,4-5.3,7.1
				c-0.7,3-3.3,4.9-4.9,7.4c-1.8,2.8-5.4,3.3-8,5.2c-1.2,1-2.7-0.2-3-1.5c-0.8-3.6,1-7.5-0.9-10.9c-1.9-3.4,3.1-5.5,2.4-8.8
				c-0.1-2-1.5-3.5-2.7-4.9c-1.9-1.8-0.2-4.5,0-6.6c-1.2-1-2.5-1.8-4-2.2c-3.7-1-7.1,2.4-10.8,1.1c-3.8-1.4-6-5-8.6-7.9
				c0.6-1.1,1.3-2.2,1.6-3.4c0.3-2.1-1.5-4.2-0.5-6.3c0.8-2.6,3-4.3,4.9-6.1c1.9-1.9,1.9-6,5.3-6.1c3.4-0.3,6.9-2.7,10.1-1.2
				c1.1,3.8,5.2,4.8,7.4,7.8c0.2-0.9-0.2-2.9,1.3-2.7c3.5-0.5,6,3.4,9.5,2.3c1.9-0.5,2.9-2.5,2.9-4.4c-4.1,0.5-7.9-1.2-9.6-5
				c-0.5,0.1-0.9,0.2-1.4,0.3c0.5,1,1,2,1.2,3.1c-1.8-1.3-2.1-3.9-4.2-5c-2.4-1.2-3.8-3.8-6.3-5c1,3,5.1,4.1,4.8,7.7
				c-1.9-1.9-3.7-4-5.8-5.7c-1.5-1.5-4.4-0.9-5.4,0.9c-1.4,1.7-2.2,3.7-3.6,5.3c-2,0.8-4.6-0.1-5.5-2.1c-0.3-1.9,0.5-3.8,0.8-5.7
				c1.5-0.1,3,0.1,4.5,0.3c-0.1-2.2-2.4-3.5-2.3-5.7c1.2-0.5,2.8-0.6,3.6-1.8c1.5-2.3,4.4-3.8,3.9-7c0.7,0.5,1.5,0.9,2,1.7
				c0.2,0.8-0.3,2.4,1,2.4c2.6,0.2,5.2,0.3,7.7,0.9c-1-1.6-3.6-2.5-3.1-4.7c1-0.3,2.1-0.7,3.1-1c-1.7-1.6-4.6-2.3-5.3-4.8
				c-0.4-1.3,1-2,1.8-2.7c-0.2-2.1-2.8-1.6-3.2,0.2c-1.4,1.9-0.4,3.9,1.1,5.3c-0.4,1.8-0.4,3.6-0.2,5.4c-0.3-0.1-0.8-0.2-1-0.3
				c-0.9-1.6-1.5-3.7-3.4-4.3c-1.2,0-2.3,0.6-3.5,0.5c-1.8-1-1.6-3.4-0.6-4.9C1497.6,165.4,1499,161.7,1501.7,158.9z M1520.7,189.4
				c-0.1,0.9-0.2,1.8-0.2,2.7c-1.5,0-2-1.7-3.2-2.3c-1.3,1-1.2,2.8-1,4.3c3.2-1.3,6,0.6,9,1.2c0.2-1.5-1.5-2-2.4-2.8
				C1521.7,191.8,1522.1,189.9,1520.7,189.4z M1528.6,191.1c-0.5,2.9,2.9,4.4,3.2,7.1c0.3,2.4,3,2.9,5,2.6c-0.1-3.5-6.4-4.2-4.6-7.9
				C1532.8,191.1,1529.9,190.7,1528.6,191.1z"
        />
      </svg>
    </div></div>
  );
};

export default memo(AnimationPathWorldEnvDay);
