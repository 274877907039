import React, { memo, useState, useEffect } from 'react';
import { SEO, Card, InputField, ExpandingButton, Switch, Spinner } from '@components';
import { LandingTopAnimated } from '@containers';
import * as Authorization from '@services/auth';
import firebase from 'gatsby-plugin-firebase';

import './covid-form.scss';

const CovidForm = () => {
  const [user, setUser] = useState(Authorization.getUser());
  const [covidData, setCovidData] = useState([]);
  const [covidDataLoaded, setCovidDataLoaded] = useState(false);
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    let nowDate = new Date();
    let date = nowDate.getFullYear() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate();

    firebase
      .database()
      .ref(`/covid/${date}/`)
      .once('value')
      .then(snapshot => {
        let result = [];
        let keys = Object.keys(snapshot.val());
        keys.forEach(function(key) {
          result.push({ ...snapshot.val()[key], key });
        });
        console.log(result);
        setCovidData(result);
        setCovidDataLoaded(true);
      })
      .catch(e => console.log(e));
  };

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
    }
    fetchData();
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    let username = user.email.split('@')[0];
    let nowDate = new Date();
    let date = nowDate.getFullYear() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate();
    firebase
      .database()
      .ref(`/covid/${date}/${username}`)
      .set({
        temperatureOK: form[0].checked,
        symptomsOK: form[1].checked,
      })
      .then(r => {
        handleServerResponse(true, 'Data logged, stay safe !', form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <div>
      <SEO title="COVID Survey" />
      <LandingTopAnimated className="on-covid-form-animated" />
      <div className="on-covid-form-container">
        <Card className="on-normal on-no-animation-normal">
          <div className="on-covid-form-form">
            <div>
              <h1>Daily COVID survey</h1>
              <p>Please fill out this survey before leaving your house.</p>
            </div>
            <div>
              <form onSubmit={handleOnSubmit} className="form">
                <div className="on-covid-question">
                  <div className="q1">I took my temperature today and I did NOT have a fever</div>
                  <div className="s2">
                    <label htmlFor="cb1">No</label>
                    <Switch id="cb1" />
                    <label htmlFor="cb1">Yes</label>
                  </div>
                  <div className="q2">
                    I am feeling well and am not experiencing flu-like symptoms
                  </div>
                  <div className="s1">
                    <label htmlFor="cb2">No</label>
                    <Switch id="cb2" />
                    <label htmlFor="cb2">Yes</label>
                  </div>
                </div>
                <div className="on-covid-form-from-button">
                  <ExpandingButton
                    type="submit"
                    buttonText="Submit"
                    disabled={serverState.submitting}
                  />
                </div>
                {serverState.status && <p className="on-form-submit">{serverState.status.msg}</p>}
              </form>
            </div>
          </div>
        </Card>

        <Card className="on-normal on-no-animation-normal">
          <div className="on-covid-form-form">
            <div>
              <h3>Daily COVID survey - Status</h3>
              {covidDataLoaded ? (
                <table className="on-covid-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Temperature</th>
                      <th>Symptoms</th>
                    </tr>
                  </thead>
                  <tbody>
                    {covidData.map(el => {
                      return (
                        <tr key={el.key}>
                          <td>{el.key}</td>
                          <td>{el.temperatureOK ? 'OK' : 'NOT OK'}</td>
                          <td>{el.symptomsOK ? 'OK' : 'NOT OK'}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <Spinner />
              )}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default memo(CovidForm);
