import React, { memo, useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';
import '../landing-animation.scss';
import AnimationPath from '../animation-path';

const AnimationPathMothersDay = () => {
  useEffect(() => {
    let pathEls = document.querySelectorAll('path.on-ee');
    for (let i = 0; i < pathEls.length; i++) {
      let pathEl = pathEls[i];
      let offset = anime.setDashoffset(pathEl);
      pathEl.setAttribute('stroke-dashoffset', offset);
      let anim = anime({
        targets: pathEl,
        strokeDashoffset: [offset, 0],
        duration: anime.random(5000, 9000),
        delay: anime.random(200, 200),
        loop: false,
        direction: 'alternate',
        easing: 'easeInOutBack',
        translateX: [2, -2],
        translateY: [-2, 2],
        autoplay: true,
      });
      anim.play();
    }
  }, []);

  return (
    <div>
      <AnimationPath />
      <div className="anim">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMinYMin slice "
          fill="var(--bg-color)"
          viewBox="0 0 1920 1080"
          width="100%"
        >
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1481.9,168.1c4.4-11,15-17.3,23.8-24.5c-1.8,13,10.7,22.4,9.6,35.3c0.5,10.3-10.8,20.5-20.8,15
			c-0.1-12.7,0.3-25.6,4.6-37.7c-7.9,10.5-8.3,24.2-8.2,36.7C1481.7,188.9,1477.9,177,1481.9,168.1z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1513.4,195.3c15.1-12,37.4-6,49.5,7.5c-13,3.7-17.4,18.2-28.4,24.5c-7.5,4.5-16.6,4-24.1-0.1
			c1.9-9.8,10.3-17.1,19.2-20.9c7.7-3.6,16.3-3.6,24.5-4.4c-15-2-32.4,0.5-42.5,13.1C1509.5,208.6,1506.9,200,1513.4,195.3z"
          />

          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1370.5,206.9c10.8-10.6,22.2-23.6,38.3-24.9c6-0.5,11,3.5,13.9,8.5c-9.2,11.9-24.1,16.1-36.6,23.3
			c-9.8,2.3-20.1,4.2-28,11c-2.3-3.6-6.2-5.7-10.5-5.8c3.4,1.8,6.4,4.4,8.6,7.5c-7,7.9-6.4,19.5-12.4,27.9
			c-7.7-4.2-9.2-14.2-9.9-22.2c0-10.4,7.2-20.5,17.3-23.3C1357.5,207,1364.5,210.2,1370.5,206.9z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1476.2,188c3.4-0.5,5.9,2.4,8.1,4.5c4,4.3,10.4,5.3,14.4,9.7c6.5,6.3,10,16.2,6.4,24.9
			c-1.4,4.7-7.5,4-11.2,3.1c-5.1-3.7-8.3-9.4-13.2-13.4c0.4-4.4,2.6-8.3,5.2-11.8c-3.9,2-6.3,5.8-7.8,9.8
			c-7.6-4.9-16.3-8.9-25.5-8.7c-13.1,0.5-27,5.2-39.6-0.5c6.6-3.5,12.8-8,19.9-10.6c9.8-3.6,20.8-1.5,29.9,3.2
			C1466,193.6,1470.2,188.7,1476.2,188z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1388.9,232c-2.7-14.6,14.7-23.2,27.1-22c-0.3,0-0.8,0.1-1,0.1c6.3,7,3.2,18.9-5.9,21.6
			c-8.5,2.4-15.5,7.8-21.2,14.5c-0.5,0.4-1,0.8-1.5,1.1c2.7,0.1,5.3,1,7.7,2.3c-0.8,1.9-1.6,3.8-2.4,5.8c-5.6-1.3-12.6-2.1-16.8,2.7
			c2.7-0.8,5.4-1.6,8.1-2.2c-1.3,5.1-1.8,10.5-0.3,15.6c2.1,6.9,0.5,14.7,4.5,20.9c4.8,7.8,13.7,12.6,22.6,14c-3-0.1-6,0-9,0.1
			c-14.2,1.2-27.8-10-30.9-23.6c-1.8-10.7-1.2-22.2,2.4-32.5C1376,242.9,1381.3,235.6,1388.9,232z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1442.2,214.5c11.1-7.5,27.2-4.5,36,5.1c7.1,6.4,12.1,14.8,16,23.5c6.8,15.3,4.4,33.1-2.3,48
			c-5,11-13.2,21.6-25,25.6c0.4-2.1,1.1-4.2,3.1-5.3c12.1-8.4,15.3-23.7,20.1-36.7c3.3-8.3,1.3-17.3-1.3-25.5
			c-2.6-7.8-7.2-17.8-16.9-17.4c-4.2-4.6-8.8-9.5-15.1-10.9c-8.7-1.6-17.6,1-25,5.5C1434.1,221.7,1437.7,217.5,1442.2,214.5z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1369.8,224.4c5.8-2.2,11.6-4.9,17.9-4.9c-1.2,3.8-1.4,8.2-4.4,11.1c-8.3,6.7-15,15.9-17.5,26.4
			c-0.5,12-1.5,24.8,4,35.9c2.1,5,7.5,7.2,11.2,10.9c-2.9,2.7-6.6,5.7-10.9,4.9c-6.3-1.2-10.6-6.6-13.9-11.7
			c-5.2-8.8-10.5-18.1-11.6-28.4c-0.9-6.9,2.8-13,5.6-19c2.5-5.2,2.1-11.5,5.6-16.3C1359.4,228.8,1364.7,226.4,1369.8,224.4z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1491.6,233.6c15.4-3.4,33.9,3.2,40.1,18.6c7.8,14.6,10.7,37.1-4.9,47.6c3.2,5.1,5.6,11.4,3.8,17.5
			c-1.6,6.5-8.2,10.7-14.6,10.8c-9.6,0.4-18.8-3.3-28.3-3c-17-0.8-33.4,6.2-46.6,16.5c7.2-9.4,17.5-16.1,28.3-20.6
			c13.2-3.3,28-2.2,39.9-9.8c9.1-6.2,9-19,4.8-28.1c3.5,1.6,6.6,3.8,9.8,5.9c-2-2.7-4.4-5-7-7.1c8.5-13-0.3-28.7-10.7-37.6
			c-3.8-3.8-7.5-8-12.7-9.9C1492.7,234.2,1492.1,233.9,1491.6,233.6z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1333.3,278.4c4-6.3,6.3-13.3,9.1-20.2c0,7.7-1.5,15.6,1.3,23c5.7,14.2,14.8,28.1,28.6,35.7
			c-1.3,6.6-2.3,13.4-1.6,20.2c-13.1,0.2-23.8-9.8-28.7-21.3c-2.4-5.3-4-11.8-9.7-14.6c1.3-1.3,2.5-2.6,3.8-4
			C1330.3,292.8,1329.5,284.2,1333.3,278.4z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1475.5,266.3c0.8-0.2,1.7-0.3,2.5-0.5c1.6,6.3,0.7,12.8-1.3,18.9c-4.2,13-10.5,25.4-18.8,36.4
			c-0.5-3.5-1.9-7.3-5.6-8.5c-10.8-4.2-22.8-3.3-33.8-7.1c-9.2-3.7-15-13.1-17-22.4c4.4,6.5,10.1,12.8,18,14.7
			c12.6,3.2,25.3-4.4,37.9-0.3c-0.1-0.5-0.2-1.5-0.3-2c0.7,3.7,0.5,7.6,0.7,11.4c2.9-4.5,2.2-10,1.5-15c0.2,0.4,0.5,1.2,0.6,1.6
			c4.8-9.2,16.7-14.7,15.5-26.6L1475.5,266.3z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1385,305.8c1.3,0.8,2.8,1.4,3.8,2.6c2.6,6,4.6,12.2,7.4,18.1c3.3,6.5,10,10.5,16.9,12.3
			c-5.7,7.1-7.6,16.5-6.9,25.4c1.2-9.5,4.2-19.9,12-25.9c1.3,0,2.5,0.2,3.8,0.3c0.1,4.8-0.9,10.4,2.4,14.4c3.4,4.1,9.4,4.5,14.1,2.8
			c8.4-2.9,14.6-9.7,21.9-14.6c8.4-6.1,18.5-8.9,28.4-11.8c3.9-0.3,7.4,1.8,10.8,3.4c-4.9,3.9-9.8,8-12.7,13.7
			c-4.6,8.7-7.8,18.7-15.7,25.1c-5.9,5.1-14.6,6.3-21.6,3c-5.9-2.4-10.7-7.6-17.3-8.1c-12.7-0.4-22.6,11.6-35.5,9.8
			c-8.7-2-13.1-10.8-16.8-18.1C1371.9,341.7,1371.3,319.4,1385,305.8z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1399.2,310.2c6.8-0.6,13.7,0.5,20.5,0.4c12.1-0.4,27.6,0.5,33.7,12.9c-6.4-0.6-12,3.3-18.3,3.4
			c-7.7,0.5-15.3-2.1-21.9-5.9C1408.2,318,1402.3,315.6,1399.2,310.2z"
          />

          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1415,210.1c0.3,0,0.8-0.1,1-0.1c7.7,0.1,15,4.1,22.8,3.3c-6.2,4-11.1,10-13.7,16.9c-1.3,3.8-4.8,6.2-8.7,6.6
			c-8,1.3-15.8,4.8-21,11.2c-2.5-0.6-5-1.1-7.5-1.8c5.6-6.7,12.7-12.1,21.2-14.5C1418.2,229,1421.2,217.1,1415,210.1z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1431.6,226.4c7.4-4.5,16.4-7.1,25-5.5c6.3,1.4,10.9,6.3,15.1,10.9c9.7-0.4,14.4,9.6,16.9,17.4
			c2.7,8.2,4.6,17.2,1.3,25.5c-4.8,13-8,28.3-20.1,36.7c3.5-5.5,5.2-11.9,7.5-17.9c2.5-7.8,5.3-15.8,4.8-24.1c0-2.9-1.9-5.1-3.9-6.8
			c3.8-6.6,3.4-15.6-2.8-20.6c-3.2-3.1-8.1-2.8-11.6-5.2c-3-3.6-4.3-9-9.5-10.2c-8.1-3.5-16.2,1.1-24.3,2.2
			C1430.6,227.9,1431.1,227.1,1431.6,226.4z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1431.4,232.7c3.8,0.1,7.8-0.3,11.2,1.4c4.9,2.5,7.2,8,11.9,10.6c3.9,2.2,9,3.3,11.5,7.5
			c2.4,4,1.3,9,0.1,13.3c-1.8,2.6-3.9,5-4.6,8.2c-2.2-1.4-4.7-2.8-5.8-5.4c-2.3-5,0.3-10.8-1.8-15.8c-2.7-4.7-8.7-5.4-13.6-6.1
			c-1-7.2-8.8-9-14.9-7.3C1427.4,236.9,1429.3,234.7,1431.4,232.7z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1426.5,249.1c5.8-1.5,13.2-1.3,17.8,2.9c2.6,5.8,6.8,11.4,4.9,18.3c-4.3-4.5-5.8-11.7-11.9-14.2
			c-4.8-2-9.1,2.5-13.9,1.8C1423,254.7,1422.6,250.2,1426.5,249.1z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1420.2,264.5c-5.3-3-5.4-11.7,0.9-13.6c0,2.9-1.4,7.1,1.9,8.7c4.9,2,9.8-2.1,14.9-1.1
			c2.4,0.7,4.1,2.7,5.9,4.4C1436.1,264.2,1427.4,269.4,1420.2,264.5z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1455.8,285.2c4.7-7.7,12.3-13.4,19.6-18.4c1.2,11.8-10.8,17.4-15.5,26.6c-0.2-0.4-0.5-1.2-0.6-1.6
			C1458.4,289.5,1457.2,287.3,1455.8,285.2z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1396.1,326.5c-2.8-5.9-4.8-12.1-7.4-18.1c11.5,7.1,19.9,19.4,33.8,22.4c-3.5,2.2-6.7,5-9.5,8
			C1406.1,337,1399.5,333,1396.1,326.5z"
          />

          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1431.4,232.7c5.7-4.1,13.3-6.3,20.1-3.9c4.8,1.9,6.8,7.3,7.2,12c7.9-0.2,16.6,5.6,17.4,13.9
			c0.1,5.7-6,8-10,10.7c1.1-4.3,2.3-9.3-0.1-13.3c-2.5-4.1-7.6-5.2-11.5-7.5c-4.8-2.6-7.1-8.1-11.9-10.6
			C1439.2,232.4,1435.2,232.8,1431.4,232.7z"
          />

          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1493.3,234.4c5.2,1.9,8.9,6.1,12.7,9.9c10.4,8.8,19.2,24.6,10.7,37.6c-4.2-2.4-8.9-3.9-13.7-4.1
			c0.3-3.3,0.6-6.6,0.9-10c3.4-0.1,6.9-0.1,10.3-0.2c-3.3-1.4-6.8-2-10.4-2.2C1503.5,254.5,1501.1,242.8,1493.3,234.4z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1403.8,244.6c6.5-6.5,17.1-6.3,25-2.4c-8.6,1.2-19.1,4.8-20.8,14.6c-2.3,9.2,6.2,18.2,15.5,17
			c11.6-2,23.8-3,35.3,0.5c0.8,7.5-8.6,6.2-13.3,5.1c-6.8-2.5-11.6,5.1-18.3,4.2c-11.5-0.6-24.8-5.3-30.2-16.3
			C1393.5,259.3,1398.2,250.4,1403.8,244.6z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1383.1,255.8c2.9,0.6,8.5-0.3,8.3,4c-0.1,5,3,9,4.9,13.3c1,8.6,1.7,17.6,6.1,25.3c2,3.6,5.9,5.4,9,8
			c-0.4-0.1-1.1-0.2-1.4-0.2c-8.9-1.4-17.7-6.2-22.6-14c-4.1-6.3-2.5-14-4.5-20.9C1381.2,266.3,1381.7,260.9,1383.1,255.8z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1400,277.5c7.7,6.9,17.9,12.3,28.5,11.5c4.8-2.3,9.5-5.9,15.3-5c6.6-0.1,13,4.5,13.4,11.4
			c0.1,0.5,0.2,1.5,0.3,2c-12.5-4.1-25.2,3.5-37.9,0.3c-7.9-1.9-13.7-8.2-18-14.7C1400.7,281.3,1400.4,279.4,1400,277.5z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1502.3,281.6c3.9-0.8,7.9,0,11.6,1.5c4.2,9.1,4.4,21.9-4.8,28.1c-11.8,7.6-26.6,6.4-39.9,9.8
			c4.1-2.8,8.6-4.9,12.7-7.7C1492.8,306.2,1498.7,293.7,1502.3,281.6z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1418.1,338.3c6.6-7,16.3-7.2,25.1-9.1c-2,5-5.8,9.2-6.9,14.5c-0.8,2.9,2.3,5.3,5,4.8
			c2.6-3.2,4.3-7.4,8.4-9.2c11.9-6.3,25.2-12.3,39-9.9c-9.8,2.8-20,5.7-28.4,11.8c-7.2,4.9-13.4,11.7-21.9,14.6
			c-4.7,1.7-10.8,1.3-14.1-2.8c-3.3-4-2.3-9.6-2.4-14.4C1420.7,338.5,1419.4,338.4,1418.1,338.3z"
          />
        </svg>
      </div>
    </div>
  );
};

export default memo(AnimationPathMothersDay);
