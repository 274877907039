import PropTypes from 'prop-types';
import React, {memo} from 'react';
import { Image, Card } from '@components';
import { partners } from '@content';
import './landing-partners.scss';

const PartnerItem = props => {
  const { img } = props;
  return (
    <Card className="on-normal">
      <div className="on-partner-item">
        <Image className="on-no-padding" filename={img} />
      </div>
    </Card>
  );
};

PartnerItem.propTypes = {
  img: PropTypes.string,
};

PartnerItem.defaultProps = {
  img: '',
};

const LandingPartners = () => {
  return (
    <div className="on-partner-container">
      <h2>Our partners </h2>
      <div className="on-partner-container-items">
        {partners.map(item => (
          <PartnerItem key={item.id} img={item.imageUrl} />
        ))}
      </div>
    </div>
  );
};

export default memo(LandingPartners);
