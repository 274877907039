import React, { memo, useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';
import '../landing-animation.scss';
import AnimationPath from '../animation-path';

const AnimationPathCanadaDay = () => {
  useEffect(() => {
    let pathEls = document.querySelectorAll('path.on-ee');
    for (let i = 0; i < pathEls.length; i++) {
      let pathEl = pathEls[i];
      let offset = anime.setDashoffset(pathEl);
      pathEl.setAttribute('stroke-dashoffset', offset);
      let anim = anime({
        targets: pathEl,
        strokeDashoffset: [offset, 0],
        duration: anime.random(4000, 4000),
        delay: anime.random(200, i*100),
        loop: false,
        direction: 'alternate',
        easing: 'easeInOutBack',
        translateX: [4, -8],
        translateY: [-8, 4],
        autoplay: true,
      });
      anim.play();

     
    }
  }, []);

  return (
    <div>
      <AnimationPath />
      <div className="anim">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMinYMin slice "
          fill="var(--bg-color)"
          viewBox="0 0 1920 1080"
          width="100%"
        >
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1481.6,277c1.8-3.7,3.6-7.4,5.3-11.1c0.5-1,1-2,1.5-3c0.1-0.3,0.4-1.3,0.7-1.5c0.3-0.1,1.4,0.4,1.7,0.6
		c9.1,3.3,18.1,6.6,27.2,9.9c3,1.1,5.9,2.2,8.9,3.2c-4.2,1.5-8.4,3-12.6,4.5c-8.6,3.1-17.3,6.2-25.9,9.3c-7.4,2.7-14.8,5.3-22.2,8
		c-0.9,0.3-1.9,0.7-2.8,1c-0.3,0.1-1,0.5-1.3,0.5c-0.3-0.1-0.8-0.7-1-0.9c-1.1-1.1-2.2-2.2-3.4-3.3c-5.7-5.6-11.3-11.2-17-16.8
		c-0.1-0.1-0.3-0.3-0.4-0.4 M1409.3,298.4c-2.2-0.8-4.3-1.5-6.5-2.3c-5.2-1.9-10.3-3.7-15.5-5.6c-6.2-2.2-12.4-4.5-18.6-6.7
		c-5.4-1.9-10.8-3.9-16.2-5.8c-1.8-0.6-3.6-1.3-5.3-1.9c-0.6-0.2-1.1-0.4-1.7-0.6c-0.2-0.1-0.8-0.2-0.9-0.3c0,0-0.1,0-0.1,0
		c6.6-2.4,13.2-4.8,19.8-7.2c5.7-2.1,11.4-4.2,17.2-6.2c0.2-0.1,0.7-0.3,0.9-0.2c0.2,0.1,0.5,1,0.6,1.2c0.9,1.8,1.7,3.6,2.6,5.4
		c1,2.1,2,4.1,3,6.2c0.3,0.7,0.7,1.4,1,2.1c0.1,0.2,0.2,0.7,0.4,0.8c0.3,0.2,1.3,0,1.6,0c1.4,0,2.8,0,4.2,0c4.4,0,8.7,0,13.1,0
		c4.6,0,9.2,0,13.7,0c2.7,0,5.4-0.2,8.1,0c0.1,0,0.3,0,0.4,0c-6.4,6.3-12.7,12.5-19.1,18.8C1411.1,296.6,1410.2,297.5,1409.3,298.4z
		 M1369.5,262c-4.8-4.6-9.5-9.2-14.3-13.8c-0.7-0.7-1.4-1.3-2.1-2c4.7,0.4,9.5,0.7,14.2,1.1c2.6,0.2,5.1,0.4,7.7,0.6
		c0.3,0,0.8,0,1,0.1c0.2,0.2,0.4,0.8,0.5,1c0.3,0.6,0.6,1.3,0.9,1.9c1.1,2.4,2.3,4.7,3.3,7.1C1377,259.4,1373.3,260.8,1369.5,262z
		 M1354.6,194.1c4,1.9,7.9,3.7,11.9,5.6c7.9,3.7,15.9,7.4,23.8,11.2c6,2.8,12,5.6,18,8.4c0.3,0.2,1.3,0.4,1.5,0.7
		c0.2,0.2,0,0.5-0.2,0.9c-1.2,3.4-2.5,6.7-3.7,10.1c-1.3,3.7-2.7,7.4-4,11c-0.1,0.4-0.7,1.4-0.6,1.8c0.1,0.5,1.2,1.2,1.5,1.5
		c0.8,0.8,1.5,1.5,2.3,2.3c0.4,0.4,0.8,0.8,1.2,1.2c-6.4,2.3-12.8,4.6-19.2,6.9c-0.6,0.2-1.3,0.5-1.9,0.7c-0.2,0.1-0.8,0.4-1,0.4
		c-0.4-0.1-1-1.7-1.2-2.3c-1.5-3.2-3.1-6.5-4.6-9.7c-1.8-3.9-3.7-7.8-5.5-11.7c-3.7-7.9-7.4-15.8-11.1-23.7
		c-2.4-5-4.7-10.1-7.1-15.1C1354.7,194.2,1354.6,194.1,1354.6,194.1z M1395.4,178.1c4.4,5,8.8,9.9,13.2,14.9c2.4,2.7,4.7,5.3,7.1,8
		c0.2,0.3,0.9,0.8,1,1.2c0,0.3-0.3,0.9-0.4,1.2c-0.3,0.8-0.6,1.6-0.9,2.4c-1.4,3.6-2.7,7.3-4.1,10.9c-3-1.4-6-2.8-8.9-4.2
		c-1.7-0.8-3.4-1.6-5.1-2.4c-0.6-0.3-0.8-0.2-0.9-0.9c-0.4-7.6-0.5-15.3-0.7-22.9C1395.6,183.5,1395.5,180.8,1395.4,178.1z
		 M1435.7,160.8c1.3,3.5,2.6,6.9,3.8,10.4c2.7,7.3,5.4,14.6,8.1,21.8c2.4,6.6,4.9,13.2,7.3,19.8c0.6,1.6,1.2,3.2,1.8,4.8
		c0.1,0.3,0.2,0.3,0.1,0.5c-0.1,0.2-0.7,0.3-0.8,0.4c-0.5,0.3-1,0.5-1.6,0.7c-6.1,2.9-12.1,6-18.3,8.7c-0.5,0.2-0.4,0.2-0.9-0.1
		c-1.1-0.5-2.2-1-3.3-1.5c-3.5-1.7-7.1-3.3-10.6-5c-1.5-0.7-3.1-1.4-4.6-2.2c-0.5-0.2-0.9-0.4-1.4-0.7c-0.1-0.1-0.7-0.2-0.7-0.3
		c-0.1-0.3,0.5-1.5,0.6-1.8c0.6-1.6,1.2-3.3,1.8-4.9c1.5-4.2,3.1-8.3,4.6-12.5c2.4-6.5,4.8-13.1,7.2-19.6c2.2-5.9,4.4-11.8,6.6-17.7
		C1435.5,161.4,1435.6,161.1,1435.7,160.8z M1476.1,178.1c-0.2,6.7-0.4,13.5-0.6,20.2c-0.1,3.5-0.2,7-0.3,10.4c0,0.2,0.1,0.7,0,0.9
		c-0.2,0.4-0.8,0.5-1.2,0.7c-1,0.5-2,0.9-3,1.4c-3.6,1.7-7.2,3.4-10.7,5c-1.2-3.1-2.3-6.3-3.5-9.4c-0.6-1.6-1.2-3.1-1.8-4.7
		c-0.2-0.6,0-0.6,0.4-1c5.1-5.8,10.3-11.7,15.4-17.5C1472.5,182.1,1474.3,180.1,1476.1,178.1z M1493,244.5c-1.1,2.3-2.1,4.5-3.2,6.8
		c-0.7,1.4-1.3,2.8-2,4.2c-0.2,0.3-0.4,1.2-0.7,1.2c-0.5,0.1-1.6-0.6-2-0.7c-1.3-0.5-2.6-0.9-4-1.4c-3.3-1.2-6.6-2.4-9.9-3.6
		c-2.1-0.7-4.1-1.5-6.2-2.2c1.2-1.2,2.4-2.4,3.6-3.6c0.3-0.3,1.3-1,1.5-1.5c0-0.1-0.1-0.3-0.1-0.5c-1-3.1-2.2-6.1-3.4-9.2
		c-1.4-3.8-2.8-7.7-4.2-11.5c-0.2-0.6-0.4-1.1-0.6-1.7c0-0.1-0.3-0.6-0.3-0.8c0-0.1,0.5-0.3,0.7-0.4c5.3-2.5,10.7-5,16-7.5
		c8-3.8,16-7.5,24-11.3c3.2-1.5,6.3-3,9.5-4.5c1.1-0.5,2.1-1,3.2-1.5c0.5-0.2,1.1-0.7,1.7-0.8c0.1,0,0.2-0.1,0.2-0.1
		c-2.7,5.7-5.3,11.4-8,17c-4.2,9.1-8.5,18.1-12.7,27.2C1495,240.3,1494,242.4,1493,244.5z M1518.2,246.1c-3.5,3.4-7,6.7-10.4,10.1
		c-1.8,1.7-3.6,3.5-5.3,5.2c-0.3,0.3-0.4,0.5-0.7,0.5c-0.3,0-0.8-0.3-1.1-0.4c-0.6-0.2-1.3-0.5-1.9-0.7c-2.6-1-5.3-1.9-7.9-2.9
		c1-2.2,2-4.3,3-6.5c0.5-1.1,0.9-2.3,1.5-3.3c0.3-0.4,0.2-0.3,0.7-0.4c5.5-0.4,11-0.8,16.4-1.2
		C1514.4,246.4,1516.3,246.3,1518.2,246.1z M1462.3,251.3c6,2.1,11.9,4.3,17.9,6.4c1.8,0.6,3.6,1.3,5.4,1.9c-1.7,3.5-3.4,7.1-5,10.6
		c-0.4,0.8-0.7,1.5-1.1,2.3c-0.1,0.2-0.1,0.4-0.3,0.5c-0.2,0.1-0.6,0-0.8,0c-0.6,0-1.1,0-1.7,0c-9.3,0-18.5,0-27.8,0
		c-2.9,0-5.7,0-8.6,0c6.4-6.3,12.9-12.7,19.3-19C1460.5,253.1,1461.4,252.2,1462.3,251.3z M1385.6,259.6c7.5-2.7,15-5.4,22.5-8.1
		c0.2-0.1,0.8-0.4,1-0.4c0.1,0,0.4,0.3,0.5,0.4c1,1,2,2,3,3c3.6,3.6,7.2,7.2,10.8,10.8c2.6,2.6,5.1,5.1,7.7,7.7
		c-7.3,0-14.5,0-21.8,0c-4.5,0-9,0-13.5,0c-1.1,0-2.3,0.2-3.4,0c-0.1,0-0.3,0-0.3,0c-0.3-0.2-0.5-1.1-0.7-1.4
		C1389.4,267.6,1387.5,263.6,1385.6,259.6z M1461,248.1c-6.7,6.7-13.4,13.3-20.1,20c-1,1-1.9,1.9-2.9,2.9c0-8.9,0-17.8,0-26.7
		c0-4.2,0-8.3,0-12.5c0-0.9,0.2-0.8,1-1.1c2.1-1,4.2-2,6.3-3c4.5-2.1,8.9-4.2,13.4-6.3c1.4,3.8,2.8,7.6,4.1,11.5
		c0.9,2.4,1.8,4.9,2.6,7.3c0.2,0.7,0.5,1.4,0.7,2c0.1,0.5,0.2,0.4-0.2,0.8C1464.4,244.6,1462.7,246.4,1461,248.1z M1413.4,221.5
		c3.8,1.8,7.6,3.6,11.5,5.5c2.4,1.1,4.7,2.3,7.1,3.4c0.6,0.3,1.8,0.6,2,1.2c0.1,0.4,0,1,0,1.4c0,0.8,0,1.6,0,2.4c0,2.4,0,4.8,0,7.1
		c0,5.9,0,11.9,0,17.8c0,3.6,0,7.3,0,10.9c-6.5-6.5-12.9-13-19.4-19.5c-2.2-2.2-4.4-4.4-6.6-6.6c-0.5-0.5-1-1-1.4-1.4
		c-0.2-0.2-0.7-0.6-0.8-0.8c-0.1-0.2,0.2-0.6,0.3-0.9c0.8-2.1,1.5-4.2,2.3-6.3C1410,230.9,1411.7,226.2,1413.4,221.5z M1537.6,274.8
		c-9.3-3.4-18.6-6.8-27.9-10.2c-1.3-0.5-2.6-1-3.9-1.4c6.5-6.3,13.1-12.7,19.6-19c0.9-0.9,1.8-1.8,2.8-2.7c-9,0.7-17.9,1.4-26.9,2.1
		c-1.3,0.1-2.6,0.2-3.8,0.3c3.1-6.5,6.1-13,9.2-19.6c4.9-10.3,9.7-20.6,14.6-30.9c1.1-2.4,2.2-4.7,3.3-7.1
		c-5.1,2.4-10.3,4.8-15.4,7.2c-8.2,3.8-16.4,7.7-24.5,11.5c-1.9,0.9-3.7,1.8-5.6,2.6c0.1-4.5,0.3-9,0.4-13.4
		c0.2-7.1,0.4-14.3,0.6-21.4c0-1.7,0.1-3.3,0.1-5c-3,3.4-6,6.8-8.9,10.2c-4.7,5.4-9.5,10.8-14.2,16.2c-1.1,1.2-2.2,2.5-3.3,3.7
		c-2-5.4-4-10.7-6.1-16.1c-3.2-8.6-6.4-17.1-9.7-25.7c-0.7-2-1.5-3.9-2.2-5.9c-2,5.4-4,10.8-6,16.2c-3.2,8.6-6.3,17.2-9.5,25.8
		c-0.7,2-1.4,3.9-2.2,5.9c-3-3.4-6-6.8-9-10.2c-4.8-5.4-9.6-10.8-14.3-16.2c-1.1-1.2-2.2-2.5-3.3-3.7c0.1,4.5,0.3,9,0.4,13.4
		c0.2,7.1,0.4,14.3,0.6,21.4c0,1.7,0.1,3.3,0.1,5c-5.1-2.4-10.3-4.8-15.4-7.2c-8.2-3.8-16.4-7.7-24.5-11.5c-1.9-0.9-3.7-1.8-5.6-2.6
		c3.1,6.5,6.1,13,9.2,19.6c4.9,10.3,9.7,20.6,14.6,30.9c1.1,2.4,2.2,4.7,3.3,7.1c-9-0.7-18-1.4-27.1-2.1c-1.3-0.1-2.5-0.2-3.8-0.3
		c6.5,6.3,13,12.7,19.5,19c0.9,0.9,1.8,1.8,2.7,2.7c-9.3,3.4-18.6,6.8-27.9,10.2c-1.3,0.5-2.6,1-3.9,1.4c4.9,1.8,9.8,3.5,14.6,5.3
		c10.2,3.7,20.3,7.3,30.5,11c8.7,3.1,17.4,6.3,26.1,9.4c1.2,0.4,2.3,0.8,3.5,1.3c0.4,0.1,1.2,0.6,1.7,0.6c0.4,0,0.8-0.7,1.1-0.9
		c1.3-1.3,2.6-2.6,3.9-3.9c3.9-3.8,7.7-7.7,11.6-11.5c1.6-1.6,3.2-3.2,4.8-4.7c0.5-0.5,1.1-1.1,1.6-1.6c0.2-0.2,0.5-0.6,0.8-0.8
		c0.5-0.2,1.4-0.1,1.9-0.1c0.5,0,1.3-0.2,1.8,0.1c0.3,0.2,0.6,0.6,0.9,0.8c0.5,0.5,1.1,1.1,1.6,1.6c3.7,3.6,7.3,7.2,11,10.8
		c3.1,3.1,6.2,6.1,9.3,9.2c0.3,0.3,0.6,0.7,1,0.8c0.5,0,1.3-0.5,1.7-0.6c7.4-2.7,14.8-5.3,22.2-8c10.4-3.8,20.9-7.5,31.3-11.3
		c6.7-2.4,13.5-4.9,20.2-7.3C1536.9,275,1537.3,274.9,1537.6,274.8z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1434,277c1.1,0,2.2,0,3.3,0c0.5,0,0.6-0.1,0.7,0.2c0.1,0.2,0,0.7,0,1c0,0.6,0,1.2,0,1.7c0,4.2,0,8.5,0,12.7
		c0,10.9,0,21.8,0,32.7c0,1.7,0,3.4,0,5.1c0,0.5,0,1.1,0,1.6c0,0.2,0.1,0.7,0,0.8c-0.2,0.3-0.9,0.1-1.2,0.1c-0.7,0-1.4,0-2.1,0
		c-0.1,0-0.6,0.1-0.7,0c-0.2-0.2,0-1.6,0-1.9c0-9.5,0-19.1,0-28.6c0-8.3,0-16.6,0-25C1434,277.3,1434,277.1,1434,277z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1396.1,360.4c-0.1,0.4-0.4,0.6-0.7,0.9c-0.3,0.3-0.7,0.8-1.1,1.1c-0.6,0.3-1.8,0.6-2.4,0.7
		c-0.7,0-1.6-0.2-2.1-0.5c-1-0.6-1.3-1-1.9-2c-0.8-1.3-0.7-3.6-0.3-5c0.5-1.6,1.8-2.7,3.5-2.9c1.9-0.3,3.9,0.3,5.2,1.8
		c0.5-0.6,1-1.3,1.5-1.9c0.1-0.1,0.6-0.6,0.6-0.8c0-0.2-0.3-0.3-0.4-0.4c-0.7-0.5-1.6-1.2-2.4-1.5c-0.6-0.3-1.3-0.3-1.9-0.4
		c-0.6-0.1-1.3-0.3-1.9-0.3c-0.9,0.1-1.9,0.3-2.8,0.6c-0.6,0.2-1.1,0.6-1.6,1c-0.5,0.4-1.1,0.7-1.5,1.2c-0.5,0.7-1.1,1.6-1.4,2.4
		c-0.2,0.6-0.3,1.3-0.4,1.9c-0.1,0.6-0.3,1.3-0.3,1.9c0,0.9,0.3,1.9,0.5,2.8c0.1,0.6,0.2,0.9,0.6,1.5c0.4,0.6,0.7,1.5,1.2,2
		c0.6,0.6,1.5,1,2.1,1.5c0.6,0.4,1,0.4,1.7,0.6c1.8,0.4,3.3,0.5,5.1-0.2c0.8-0.3,1.6-0.7,2.2-1.3c0.6-0.5,0.9-1.3,1.4-1.9"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1411,360c-1.1,0-2.2,0-3.3,0c0.6-1.5,1.2-2.9,1.8-4.4 M1410,348.8c-0.4-0.1-0.6-0.2-0.9,0
		c-0.2,0.2-0.3,0.7-0.4,1c-0.6,1.3-1.2,2.7-1.8,4c-1.5,3.3-2.9,6.6-4.4,9.9c-0.3,0.8-0.7,1.6-1,2.3c0.8,0,1.5,0,2.3,0
		c0.3,0,0.9,0.1,1.1,0c0.3-0.1,0.1,0,0.3-0.3c0.5-0.7,0.7-1.9,1-2.6c0.2-0.4,0.2-0.9,0.6-1.1c0.6-0.2,1.8,0,2.5,0
		c0.9,0,1.8-0.1,2.7,0c0.6,0.1,0.5,0.2,0.7,0.8c0.3,0.9,0.7,1.8,1,2.7c0.3,0.7,0.6,0.5,1.4,0.5c0.8,0,1.7,0,2.5,0
		c-2.2-5-4.4-10-6.6-15.1C1410.6,350.2,1410.3,349.5,1410,348.8z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1432,349c0,3.3,0,6.7,0,10c-2.4-2.3-4.7-4.6-7.1-6.8c-0.7-0.7-1.3-1.3-2-2c-0.3-0.3-0.7-0.9-1.1-1.1
		c-1-0.6-0.8,0.4-0.8,1.1c0,4,0,7.9,0,11.9c0,0.8,0,1.6,0,2.5c0,0.3-0.1,1.1,0,1.3c0.2,0.2,0.9,0.1,1.2,0.1c0.5,0,1,0,1.6,0
		c0.2,0,1,0.1,1.2,0c0.2-0.1,0.1-1.1,0.1-1.3c0-1.1,0-2.3,0-3.4c0-1.7,0-3.4,0-5.1c3.2,3.5,6.3,7.1,9.7,10.5c0.3,0.3,1,0.7,1.3,0.3
		c0.2-0.3,0-1.8,0-2.2c0-3,0-6,0-9c0-2.2,0-4.5,0-6.7"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1449,360c-1.1,0-2.2,0-3.2,0c0.6-1.5,1.2-2.9,1.8-4.4 M1448.1,348.8c-0.4-0.1-0.6-0.2-0.9,0
		c-0.2,0.2-0.3,0.7-0.4,1c-0.6,1.3-1.2,2.7-1.8,4c-1.5,3.3-2.9,6.6-4.4,9.9c-0.3,0.8-0.7,1.6-1,2.3c0.8,0,1.5,0,2.3,0
		c0.3,0,0.9,0.1,1.1,0c0.3-0.1,0.1,0,0.3-0.3c0.5-0.7,0.7-1.9,1-2.6c0.2-0.4,0.2-0.9,0.6-1.1c0.6-0.2,1.8,0,2.5,0
		c0.9,0,1.8-0.1,2.7,0c0.6,0.1,0.5,0.2,0.7,0.8c0.3,0.9,0.7,1.8,1,2.7c0.3,0.7,0.6,0.5,1.4,0.5c0.8,0,1.7,0,2.5,0
		c-2.2-5-4.4-10-6.6-15.1C1448.7,350.2,1448.4,349.5,1448.1,348.8z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1465,362c-0.5,0-1.2,0.1-1.6,0c-0.5-0.1-0.2,0.1-0.4-0.2c-0.1-0.3,0-0.9,0-1.2c0-0.6,0-1.3,0-1.9
		c0-1.4,0-2.8,0-4.3c0-0.5,0-1.1,0-1.6c0-0.1-0.1-0.7,0-0.8c0.2-0.3,2,0,2.4,0c0.5,0,1.3,0.1,1.8,0.3c0.4,0.1,0.7,0.5,1,0.8
		c1.4,1.2,1.6,3.4,1.3,5.2c-0.1,1-0.5,1.9-1.2,2.6c-0.3,0.3-0.7,0.7-1.2,0.9c-0.5,0.2-1.3,0.2-1.8,0.3 M1473.3,354.5
		c-0.4-0.8-0.8-1.8-1.4-2.6c-0.5-0.7-1.3-1.1-1.9-1.7c-0.7-0.5-1.3-0.6-2.1-0.8c-0.7-0.2-1.5-0.4-2.3-0.5c-1-0.1-2.1,0-3.2,0
		c-0.3,0-2.1-0.2-2.3,0c-0.1,0.1,0,1,0,1.2c0,1,0,1.9,0,2.9c0,2.6,0,5.2,0,7.8c0,1.1,0,2.2,0,3.3c0,0.3-0.2,1.4,0,1.7
		c0.2,0.3,1,0.1,1.4,0.1c1.2,0,2.4,0,3.6,0c0.7,0,1.2-0.1,1.8-0.3c0.8-0.2,1.8-0.2,2.6-0.6c0.5-0.3,1-0.8,1.5-1.2
		c0.3-0.2,0.7-0.5,0.9-0.7c0.5-0.7,1-1.9,1.3-2.7C1473.7,358.5,1473.5,356.5,1473.3,354.5"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1486,360c-1.3,0-2.7,0-4,0c0.6-1.5,1.2-2.9,1.8-4.4 M1484.1,349c-0.5,0-0.5-0.1-0.7,0.2
		c-0.2,0.2-0.3,0.7-0.4,1c-0.6,1.3-1.2,2.7-1.7,4c-1.4,3.2-2.8,6.4-4.2,9.5c-0.3,0.8-0.7,1.5-1,2.3c0.8,0,1.5,0,2.3,0
		c0.6,0,0.9,0.2,1.2-0.3c0.5-0.8,0.7-1.8,1-2.7c0.2-0.5,0.2-0.9,0.6-1c0.6-0.2,1.8,0,2.4,0c0.9,0,1.8,0,2.7,0c0.6,0,0.5,0,0.8,0.6
		c0.3,0.9,0.7,1.8,1,2.7c0.3,0.9,0.3,0.8,1.2,0.8c0.9,0,1.7,0,2.6,0c-2.2-5-4.4-9.9-6.7-14.9C1484.8,350.4,1484.4,349.7,1484.1,349z
		"
          />
        </svg>
      </div>
    </div>
  );
};

export default memo(AnimationPathCanadaDay);
