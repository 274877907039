import React, { memo } from 'react';
import { ImageCard, Card } from '@components';
import PropTypes from 'prop-types';
import './landing-overview.scss';
import { Link } from 'gatsby';

const OverviewItem = props => {
  const { title, text, img, link, gradientStyle } = props;
  const content = (
    <ImageCard
      className={'on-landing-overview-item-image'}
      imageClassName={'on-landing-overview-image'}
      contentClassName="on-landing-overview-image-content"
      filename={img}
      style={gradientStyle}
    >
      <div className="on-landing-overview-item-container">
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
    </ImageCard>
  );
  return link ? <Link to={link}>{content}</Link> : content;
};

OverviewItem.propTypes = {
  img: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
};

OverviewItem.defaultProps = {
  img: '',
  text: '',
  title: '',
};

const LandingOverview = props => {
  return (
    <div className="on-landing-overview-base">
      <h2 className="on-lading-overview-text">{props.text}</h2>
      <div className="on-landing-overview-container">
        {props.items.map(item => (
          <OverviewItem
            key={item.id}
            title={item.title}
            text={item.text}
            img={item.imageUrl}
            link={item.link}
            gradientStyle={item.gradientStyle}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(LandingOverview);
