import React from 'react';
import { useState } from 'react';
import { Link, navigate } from '@reach/router';
import * as Authorization from '@services/auth';
import firebase from 'gatsby-plugin-firebase';

const LoginStatus = () => {
  const [fb, setFb] = useState();

  React.useEffect(() => {
    setFb(firebase);
  }, []);

  const logoutHandler = () => {
    Authorization.logout(firebase).then(() => navigate(`/employee/login`));
  }

  let details;
  if (!Authorization.isLoggedIn()) {
    details = (
      <Link to="/employee/login">
        <u>Log in</u>
      </Link>
    );
  } else {
    const { displayName, email } = Authorization.getUser();
    details = (
      <p className="text-right px-5">
        Logged in as {email}
        {` `}
        <a
          href="/"
          onClick={event => {
            event.preventDefault();
            logoutHandler();
          }}
        >
          <u>log out</u>
        </a>
      </p>
    );
  }

  return <div>{details}</div>;
};

export default LoginStatus;
