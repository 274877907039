import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Header, Footer } from '@containers';
import { Transition } from '@components';
import './layout.scss';

const Layout = ({ children, location }) => {
  return (
    <>
      <Header />
      <div className="main-container">
        <main>
          <Transition location={location}>{children}</Transition>
        </main>
      </div>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(Layout);
