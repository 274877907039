import PropTypes from 'prop-types';
import React, {memo} from 'react';
import { Image, Card } from '@components';
import { technologies } from '@content';
import './landing-technology.scss';

const TechnologyItem = props => {
  const { img } = props;

  return (
    <Card className="on-normal on-technology-card">
      <div className="on-technology-item">
        <Image className="on-technology-item-image" filename={img} />
      </div>
    </Card>
  );
};

TechnologyItem.propTypes = {
  img: PropTypes.string,
};

TechnologyItem.defaultProps = {
  img: '',
};

const LandingTechnology = (props) => {
  return (
    <div className="on-technology-container">
      <h2>{props.text}</h2>
      <div className="on-technology-items-container">
        {props.items.map(item => (
          <TechnologyItem key={item.id} img={item.imageUrl} />
        ))}
      </div>
    </div>
  );
};
export default memo(LandingTechnology);
