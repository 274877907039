import React, { memo } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import './spinner.scss';
import { Image } from '@components';

const Spinner = props => {
  const { children, className, style } = props;

  return (
    <Image
      className={cs('on-spinner-base', className)}
      filename="logo_1.png"
      style={style}
    ></Image>
  );
};

Spinner.PropTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

Spinner.defaultProps = {
  className: '',
  style: {},
};

export default memo(Spinner);
