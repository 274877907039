import { Link } from 'gatsby';
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './expanding-button.scss';

const ButtonComponent = props => {
  const { buttonText } = props;

  return (
    <button className="expanding-button">
      <span className="circle" aria-hidden="true">
        <span className="icon arrow" />
      </span>
      <span className="button-text">{buttonText}</span>
    </button>
  );
};

const ExpandingButton = props => {
  const { buttonText, link } = props;
  const { className, style } = props;

  return (
    <div id="container" className={className} style={style}>
      {link ? (
        <Link to={link}>
          <ButtonComponent buttonText={buttonText} />
        </Link>
      ) : (
        <ButtonComponent buttonText={buttonText} />
      )}
    </div>
  );
};

ExpandingButton.PropTypes = {
  link: PropTypes.string,
  buttonText: PropTypes.string,

  className: PropTypes.string,
  style: PropTypes.object,
};

ExpandingButton.defaultProps = {
  link: null,
  buttonText: '',

  className: '',
  style: {},
};

export default memo(ExpandingButton);
