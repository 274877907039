import React, {memo} from 'react';
import { ExpandingButton, Card } from '@components';
import {routes} from '@appConstants'

import './landing-work.scss';

const LandingWork = (props) => (
  <Card className="on-landing-work-card">
    <div className="on-landing-work-container">
      <h2>
        {props.text}
      </h2>
      <ExpandingButton className="on-landing-button" buttonText="Contact us" link={routes.contactUs} />
    </div>
  </Card>
);

export default memo(LandingWork);
