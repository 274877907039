const folderPath = '/technologies/';

export const technologies = [
  { id: 1, imageUrl: `${folderPath}logo-nodejs.png` },
  { id: 2, imageUrl: `${folderPath}logo-react.png` },
  { id: 3, imageUrl: `${folderPath}logo-electron.png` },
  { id: 4, imageUrl: `${folderPath}logo-typescript.png` },
  { id: 5, imageUrl: `${folderPath}logo-npm.png` },
  { id: 6, imageUrl: `${folderPath}logo-html5.png` },
  { id: 7, imageUrl: `${folderPath}logo-css3.png` },
  { id: 8, imageUrl: `${folderPath}logo-sass.png` },
];

export const backendTechnologies = [
  { id: 1, imageUrl: `${folderPath}logo-nodejs.png` },
  { id: 2, imageUrl: `${folderPath}logo-typescript.png` },
  { id: 3, imageUrl: `${folderPath}logo-npm.png` },
  { id: 4, imageUrl: `${folderPath}logo-firebase.png` },
  { id: 5, imageUrl: `${folderPath}logo-graphql.png` },
  { id: 6, imageUrl: `${folderPath}logo-python.png` },
]

export const frontendTechnologies = [
  { id: 1, imageUrl: `${folderPath}logo-javascript.png` },
  { id: 2, imageUrl: `${folderPath}logo-react.png` },
  { id: 3, imageUrl: `${folderPath}logo-html5.png` },
  { id: 4, imageUrl: `${folderPath}logo-css3.png` },
  { id: 5, imageUrl: `${folderPath}logo-sass.png` },
  { id: 6, imageUrl: `${folderPath}logo-selenium.png` },
]

export const mobileTechnologies = [
  { id: 1, imageUrl: `${folderPath}logo-android.png` },
  { id: 2, imageUrl: `${folderPath}logo-apple.png` },
  { id: 3, imageUrl: `${folderPath}logo-electron.png` },
  { id: 4, imageUrl: `${folderPath}logo-react.png` },
]