export const testimonials = [
  {
    id: 1,
    author: 'Name Lastname',
    company: '(CTO, Company)',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dapibus posuere pellentesque.',
    imageUrl: '/employees/vucak_01.jpg',
  },
  {
    id: 2,
    author: 'Name Lastname',
    company: '(CTO, Company)',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dapibus posuere pellentesque.',
    imageUrl: '/employees/vucak_01.jpg',
  },
];
