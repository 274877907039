import { Link } from 'gatsby';
import React, { memo } from 'react';
import './footer.scss';
import { routes, footerRoutes } from '@appConstants';
import { MOTDs, social } from '@content';

const year = new Date().getFullYear();
const day = new Date().getDay();

const Footer = () => {
  return (
    <footer className="on-footer">
      <div className="on-footer-item on-footer-links">
        {social.map(item =>
          item.id % 2 == 0 ? <IconLink key={item.id} item={item}/> : null
        )}
      </div>

      <div className="on-footer-item on-footer-resources">
        <h3>{MOTDs.find(x => x.id === day).text} </h3>
        <p></p>© {`${year} ooden`}
      </div>

      <div className="on-footer-item on-footer-social">
        {social.map(item => item.id % 2 == 1 ? <IconLink key={item.id} item={item}/> : null)}
      </div>
    </footer>
  );
};

const IconLink = ({item}) => 
  <a key={`social-${item.id}`} className="on-footer-underline" href={item.link}>
    {item.icon}
  </a>;


export default memo(Footer);
