export const seoMetadata = (metaDescription, title, meta) => [
  {
    name: `description`,
    content: metaDescription,
  },
  {
    property: `og:title`,
    content: title,
  },
  {
    property: `og:description`,
    content: metaDescription,
  },
  {
    property: `og:type`,
    content: `website`,
  },
  {
    name: `twitter:card`,
    content: `summary`,
  },
  {
    name: `twitter:creator`,
    content: metaDescription.author,
  },
  {
    name: `twitter:title`,
    content: title,
  },
  {
    name: `twitter:description`,
    content: metaDescription,
  },
].concat(meta);
