import React, { memo } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import './card.scss';

const Card = props => {
  const { children, className, style } = props;

  return (
    <div className={cs('on-card-base', className)} style={style}>
      {children}
    </div>
  );
};

Card.PropTypes = {
  children: PropTypes.node,

  className: PropTypes.string,
  style: PropTypes.object,
};

Card.defaultProps = {
  children: null,

  className: '',
  style: {},
};

export default memo(Card);
