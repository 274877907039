//Message of the day

export const MOTDs = [ 
  { id: 1, text: '“Every programmer is an author.” - Sercan Leylek' },
  { id: 2, text: 'Just woke up, feeling like coding today. Maybe you want to join me ?' },
  { id: 3, text: '“Code never lies, comments sometimes do.” - Ron Jeffries' },
  { id: 4, text: 'Hey, you there, with your cringe smile; Have a good day.'},
  { id: 5, text: 'Are you ready for tonight? Please put on your best clothes and we will conquer the city. This night is ours.' },
  { id: 6, text: 'Let me just stop you here. Today you relax, monday we talk.'},
  { id: 0, text: 'I see the force is strong with you, but our office wont be open till monday anyway.' }, //Sunday is 0, monday is 1
];
