import React, { memo } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import './job-search.scss';
import { Link } from 'gatsby';
import { routes } from '@appConstants';

const JobSearch = props => {
  const { className, style } = props;

  return (
    <Link to={routes.contactUs}>
      <div className={cs('on-job-base', className)} style={style}>
        <p>We are hiring</p>
      </div>
    </Link>
  );
};

JobSearch.PropTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

JobSearch.defaultProps = {
  className: '',
  style: {},
};

export default memo(JobSearch);
