import { React, memo } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const Image = props => (
  <StaticQuery
    query={graphql`
      query {
        ...ImageQueryFragment
      }
    `}
    render={data => {
      const { className, style, alt, filename } = props;

      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(filename);
      });
      if (!image) {
        return null;
      }

      if (filename)
        return (
          <Img
            className={className}
            style={style}
            alt={alt}
            fluid={image.node.childImageSharp.fluid}
          />
        );
      else return <div className={className} style={style} />;
    }}
  />
);

Image.PropTypes = {
  alt: PropTypes.string,
  filename: PropTypes.string,

  className: PropTypes.string,
  style: PropTypes.object,
};

Image.defaultProps = {
  alt: '',
  filename: '',

  className: '',
  style: {},
};

export default memo(Image);
