import React, { memo } from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import { Card, ImageCard } from '@components';

import './latest-blog.scss';

const LatestBlog = ({ data }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          ...LatestBlogQueryFragment
        }
      `}
      render={data => {
        const posts = data.allMarkdownRemark.edges;
        return (
          <div className="on-latest-blog-container">
            <h2>Latest blog posts</h2>
            <div className="on-latest-blog-item-container">
              {posts.map(post => (
                <div className="on-latest-blog-item-flex" key={post.node.fields.slug}>
                  <Link className="on-blog-item" to={post.node.fields.slug}>
                    <ImageCard
                      className="on-normal on-latest-blog-card"
                      contentClassName="on-latest-blog-content"
                      filename={post.node.frontmatter.frontImage}
                    >
                      <div className="on-blog-item-grid">
                        <h3 className="on-blog-item-title">
                          {post.node.frontmatter.title} <span>— {post.node.frontmatter.date}</span>
                        </h3>
                        <p className="on-blog-item-desc">{post.node.excerpt}</p>
                      </div>
                    </ImageCard>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        );
      }}
    />
  );
};

export default memo(LatestBlog);
