export const routes = {
  home: '/',
  blog: '/blog',
  about: '/about',
  contactUs: '/contact-us',
  tags: './tags',
  solutions: './what-we-do/solutions-and-systems',
  enterprise: './what-we-do/enterprise-app-development',
  quality: './what-we-do/quality-assurance'
};

export const footerRoutes = [
  { id: 0, name: 'Home', link: routes.home },
  { id: 1, name: 'Blog', link: routes.blog },
  { id: 2, name: 'Contact us', link: routes.contactUs },
  //{ id: 3, name: 'About', link: routes.about },
];
