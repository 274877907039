import { routes } from "../app-constants/endpoint-constants";

export const overviewItems = [
  {
    id: 1,
    title: 'Solutions and system architecture',
    text: 'Get professional help designing your solutions.',
    imageUrl: '/overview/arhitecture.jpg',
    link: routes.solutions
  },
  {
    id: 2,
    title: 'Enterprise apps development',
    text: 'Our experts build high performance and mission critical platforms',
    imageUrl: '/overview/servers.jpg',
    link: routes.enterprise
  },
  {
    id: 3,
    title: 'Quality assurance',
    text: 'We do what we love and we care about you',
    imageUrl: '/wallpaper.jpg',
    link: routes.quality
  },
];
