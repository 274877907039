import PropTypes from 'prop-types';
import React, { memo } from 'react';
import cs from 'classnames';
import { Card } from '@components';
import './landing-animation.scss';
import AnimationPath from './animation-path';
import AnimationPathSW from './paths/animation-path-star-wars';
import AnimationPathMothersDay from './paths/animation-path-mothers-day';
import AnimationPathTreeDay from './paths/animation-path-tree-day';
import AnimationPathWorldEnvDay from './paths/animation-path-world-env-day';
import AnimationPathCanadaDay from './paths/animation-path-canada-day';

const month = new Date().getMonth() + 1;
const day = new Date().getDate();

const LandingTopAnimated = props => {
  const { title, text, className } = props;
  return (
    <div className={cs('on-landing-top', className)}>
      <Card className="on-landing-top-card">
        <div className="on-landing-top-container">
          {getDailyAnimation()}
          <h1 className="animated">{title}</h1>
          <p>{text}</p>
        </div>
      </Card>
    </div>
  );
};

const getDailyAnimation = () => {
  if (day == 4 && month == 5) return <AnimationPathSW />;
  if (day == 10 && month == 5) return <AnimationPathMothersDay/>
  if (day == 16 && month == 5) return <AnimationPathTreeDay/>
  if (day == 5 && month == 6) return <AnimationPathWorldEnvDay/>
  if (day == 5 && month == 7) return <AnimationPathCanadaDay/>
  else return <AnimationPath />;
};

LandingTopAnimated.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
};

export default memo(LandingTopAnimated);
