import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import * as Authorization from '@services/auth';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  useEffect(() => {
    if (!Authorization.isLoggedIn() && location.pathname !== `/employee/login`) {
      navigate(`/employee/login`);
      return null;
    }
  }, []);

  return <Component {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

export default PrivateRoute;
