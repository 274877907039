import React, { memo, useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';
import '../landing-animation.scss';

const AnimationPathTreeDay = () => {
  useEffect(() => {
    let pathEls = document.querySelectorAll('path');
    for (let i = 0; i < pathEls.length; i++) {
      let pathEl = pathEls[i];
      let offset = anime.setDashoffset(pathEl);
      pathEl.setAttribute('stroke-dashoffset', offset);
      let anim = anime({
        targets: pathEl,
        strokeDashoffset: [offset, 0],
        duration: anime.random(5000, 13000),
        delay: anime.random(200, 600),
        loop: true,
        direction: 'alternate',
        easing: 'easeInOutBack',
        translateX: [2, -2],
        translateY: [-2, 2],
        autoplay: true,
      });
      anim.play();
    }
  }, []);

  return (
    <div className="anim">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMinYMin slice "
        fill="var(--bg-color)"
        viewBox="0 0 1920 1080"
        width="100%"
      >
      <g id="Layer_2">
      <g>
        <g>
          <path stroke="var(--primary-color)" class="st5" d="M756.5,567.7c0,0.7-1,1-1.5,1.2c-1.3,0.5-2.8,0.8-4.2,1.1c-4.8,0.9-9.8,1.2-14.7,1.4
            c-6.5,0.3-13,0.3-19.5,0.1c-5.2-0.2-10.4-0.5-15.6-1.3c-1.6-0.2-3.3-0.5-4.8-1c-0.7-0.2-1.9-0.5-2.3-1.2
            c-0.8-1.5,3.6-2.2,4.3-2.4c4.6-1,9.3-1.3,13.9-1.6c6.4-0.4,12.8-0.4,19.2-0.3c5.5,0.1,11,0.4,16.4,1.1c1.8,0.2,3.6,0.5,5.4,1
            c1,0.2,2.2,0.5,3,1.2C756.3,567.2,756.5,567.4,756.5,567.7z"/>
          <g>
            <path stroke="var(--primary-color)" class="st6" d="M750.7,489.5c-2.1-1.6-3.6,0.4-4.8,1.9c-1.4,1.9-2.9,3.7-4.3,5.6c-3.7,4.8-7.5,9.7-11.2,14.5
              c-0.9,1.2-1.8,2.3-2.7,3.5c0-4.2,0-8.5,0-12.7c0-2.7,0-5.4,0-8c0-1.6,0.4-3.8-1.2-4.9c-1.8-1.2-4,0.2-4.1,2.2c0,0.3,0,0.5,0,0.8
              c0,1.7,0,3.5,0,5.2c0,5.8,0,11.7,0,17.5c0,4,0,8,0,12c-3.6-5.9-7.3-11.9-10.9-17.8c-2.6-4.2-5.1-8.4-7.7-12.5
              c-1.3-2.2-2.6-4.4-4-6.6c-1.8-2.7-6.1-0.6-4.7,2.5c0.2,0.5,0.6,1,0.9,1.5c1.7,2.8,3.5,5.6,5.2,8.4c5.2,8.5,10.5,17,15.7,25.6
              c1.7,2.7,3.3,5.4,5,8.1c0.2,0.3,0.5,0.7,0.6,1c0.2,0.9,0,2.1,0,3c0,8.5-0.1,17,0,25.5c0,0.6,0,1.1,0.3,1.7
              c0.9,1.6,3.2,1.9,4.4,0.4c0.7-0.8,0.6-1.9,0.6-2.9c0-3.6,0-7.1,0-10.7c0-9.3,0-18.6,0-27.9c0-0.8-0.2-1.9,0-2.7
              c0.1-0.4,0.4-0.7,0.6-1c2.3-2.9,4.5-5.8,6.8-8.8c5.2-6.8,10.5-13.6,15.7-20.4C751.7,492.4,752.2,490.7,750.7,489.5z"/>
            <path stroke="var(--primary-color)" class="st7" d="M785.2,467.8c0,7.4-5.4,13.4-11.1,17.4c-8.3,5.9-18.5,9-28.4,10.9c-12.2,2.2-24.9,2.4-37.2,0.7
              c-10.4-1.5-20.9-4.3-30-9.8c-6.1-3.7-12.4-9.3-13.6-16.8c-1.1-7.2,3.1-13.5,8.5-17.9c7.5-6.2,17.2-9.7,26.7-11.9
              c12-2.7,24.6-3.3,36.9-2.1c10.8,1.1,21.8,3.6,31.5,8.7c6.7,3.5,13.7,8.7,16,16.2C784.9,464.7,785.2,466.3,785.2,467.8z"/>
            <path stroke="var(--primary-color)" class="st8" d="M785.2,467.8c0,7.4-5.4,13.4-11.1,17.4c-8.3,5.8-18.4,9-28.3,10.8c-6.9,1.3-13.9,1.9-20.9,1.9
              c0-6.8,0-13.6,0-20.5c0-10.8,0-21.6,0-32.4c0-2.5,0-4.9,0-7.4c11.8,0,23.7,1.6,34.9,5.6c8,2.9,16.5,7.1,21.6,14.1
              C783.7,460.4,785.2,464,785.2,467.8z"/>
          </g>
        </g>
        <g>
          <path stroke="var(--primary-color)" class="st5" d="M1208.3,567.7c0,0.7-1,1-1.5,1.2c-1.3,0.5-2.8,0.8-4.2,1.1c-4.8,0.9-9.8,1.2-14.7,1.4
            c-6.5,0.3-13,0.3-19.5,0.1c-5.2-0.2-10.4-0.5-15.6-1.3c-1.6-0.2-3.3-0.5-4.8-1c-0.7-0.2-1.9-0.5-2.3-1.2
            c-0.8-1.5,3.6-2.2,4.3-2.4c4.6-1,9.3-1.3,13.9-1.6c6.4-0.4,12.8-0.4,19.2-0.3c5.5,0.1,11,0.4,16.4,1.1c1.8,0.2,3.6,0.5,5.4,1
            c1,0.2,2.2,0.5,3,1.2C1208.1,567.2,1208.3,567.4,1208.3,567.7z"/>
          <g>
            <path stroke="var(--primary-color)" class="st6" d="M1176.9,569c-3.4,0-2.7-4.6-2.7-6.8c0-4.4,0-8.8,0-13.2c0-10.2,0-20.3,0-30.5c0-1.4,0-2.7,0-4.1
              c0-0.9-0.1-1.9,0.5-2.7c1.1-1.6,3.6-1.5,4.5,0.2c0.3,0.5,0.3,1,0.3,1.5c0,0.9,0,1.9,0,2.8c0,9.6,0,19.2,0,28.8
              c0,4.9,0,9.7,0,14.6c0,2.2,0.1,4.5,0,6.7C1179.5,567.8,1178.4,569,1176.9,569z"/>
            <path stroke="var(--primary-color)" class="st7" d="M1209.9,507.2c0,9.7-4.4,19-11.8,25.2c-7.5,6.3-17.6,8.9-27.2,7.1c-9.3-1.7-17.6-7.6-22.4-15.8
              c-5.3-9.1-5.9-20.6-1.5-30.2c1.2-2.6,2.7-5,4.4-7.2c0.4-0.5,0.9-1,1.3-1.5c0.1-0.1,0.6-0.5,0.6-0.7c0-0.2-0.6-0.9-0.7-1.1
              c-0.7-1.2-1.3-2.5-1.9-3.9c-2.1-5.5-2.4-11.6-0.9-17.3c1.4-5.1,4.2-9.7,8.1-13.3c0.5-0.4,0.9-0.8,1.4-1.2
              c0.5-0.4,1.3-0.7,1.6-1.2c0.2-0.3,0.2,0,0.2-0.3c0-0.1-0.2-0.3-0.3-0.4c-0.2-0.3-0.4-0.7-0.5-1c-0.3-0.7-0.6-1.4-0.9-2.2
              c-0.9-2.7-1.2-5.6-0.8-8.4c1.3-9.9,10.7-17,20.6-15.8c10.1,1.3,17.4,11,15.8,21.1c-0.2,1.5-0.7,3-1.3,4.4
              c-0.3,0.7-0.6,1.3-1,1.9c-0.1,0.1-0.2,0.3-0.3,0.4c0,0.3,0,0.1,0.2,0.3c0.2,0.3,0.7,0.5,0.9,0.7c2.1,1.6,3.9,3.4,5.5,5.4
              c3.3,4.3,5.2,9.5,5.6,14.9c0.2,3,0,6-0.8,8.9c-0.4,1.5-0.9,2.9-1.5,4.3c-0.3,0.7-0.6,1.3-0.9,1.9c-0.2,0.3-0.4,0.7-0.6,1
              c-0.1,0.2-0.6,0.8-0.6,1c0,0.3,1.1,1.2,1.4,1.5c0.5,0.5,0.9,1.1,1.4,1.7c0.8,1.1,1.6,2.3,2.3,3.5c1.4,2.4,2.5,5,3.2,7.7
              C1209.5,501.4,1209.9,504.3,1209.9,507.2z"/>
            <path stroke="var(--primary-color)" class="st8" d="M1209.9,507.2c0,13.7-8.7,26.2-21.5,30.9c-3.6,1.3-7.5,2-11.4,2c0-4.1,0-8.1,0-12.2c0-9.8,0-19.5,0-29.3
              c0-11.8,0-23.5,0-35.3c0-10.2,0-20.3,0-30.5c0-3.3,0-6.7,0-10c0-1.1,0-2.1,0-3.2c0-0.4-0.2-1.3,0-1.7c0-0.1,0-0.2,0-0.2
              c6.3,0,12.3,3.3,15.6,8.7c1.6,2.6,2.6,5.7,2.8,8.8c0.1,1.9-0.1,3.9-0.6,5.8c-0.2,0.9-0.5,1.7-0.9,2.5c-0.2,0.5-0.4,0.9-0.7,1.4
              c-0.1,0.2-0.2,0.4-0.3,0.6c-0.3,0.5-0.2,0.3,0.1,0.7c0.5,0.6,1.4,1,2,1.5c0.7,0.6,1.3,1.2,1.9,1.8c1.2,1.2,2.2,2.5,3.2,3.8
              c2,2.8,3.4,6.1,4.2,9.4c0.9,3.7,1,7.5,0.3,11.2c-0.3,1.9-0.9,3.7-1.6,5.5c-0.3,0.9-0.7,1.7-1.2,2.6c-0.2,0.4-0.5,0.9-0.7,1.3
              c-0.1,0.2-0.3,0.4-0.3,0.6c-0.1,0.4-0.1,0.2,0.1,0.6c0.6,0.8,1.3,1.5,1.9,2.2c0.6,0.8,1.3,1.7,1.8,2.5c1.1,1.7,2.1,3.5,2.8,5.4
              C1209,498.6,1209.9,502.9,1209.9,507.2z"/>
          </g>
        </g>
        <g>
          <path stroke="var(--primary-color)" class="st5" d="M987.5,567.7c0,0.7-1,1-1.5,1.2c-1.3,0.5-2.8,0.8-4.2,1.1c-4.8,0.9-9.8,1.2-14.7,1.4
            c-6.5,0.3-13,0.3-19.5,0.1c-5.2-0.2-10.4-0.5-15.6-1.3c-1.6-0.2-3.3-0.5-4.8-1c-0.7-0.2-1.9-0.5-2.3-1.2
            c-0.8-1.5,3.6-2.2,4.3-2.4c4.6-1,9.3-1.3,13.9-1.6c6.4-0.4,12.8-0.4,19.2-0.3c5.5,0.1,11,0.4,16.4,1.1c1.8,0.2,3.6,0.5,5.4,1
            c1,0.2,2.2,0.5,3,1.2C987.2,567.2,987.5,567.4,987.5,567.7z"/>
          <g>
            <path stroke="var(--primary-color)" class="st6" d="M956.1,569c-3.4,0-2.7-4.6-2.7-6.8c0-4.4,0-8.8,0-13.2c0-10.2,0-20.3,0-30.5c0-1.4,0-2.7,0-4.1
              c0-0.9-0.1-1.9,0.5-2.7c1.1-1.6,3.6-1.5,4.5,0.2c0.3,0.5,0.3,1,0.3,1.5c0,0.9,0,1.9,0,2.8c0,9.6,0,19.2,0,28.8
              c0,4.9,0,9.7,0,14.6c0,2.2,0.1,4.5,0,6.7C958.7,567.8,957.6,569,956.1,569z"/>
            <path stroke="var(--primary-color)" class="st7" d="M1009.2,541.9c-3.5,0-7.1,0-10.6,0c-8.5,0-17,0-25.4,0c-10.2,0-20.4,0-30.7,0c-8.8,0-17.7,0-26.5,0
              c-2.9,0-5.8,0-8.8,0c-0.9,0-1.8,0-2.7,0c-0.3,0-1.1,0.2-1.4,0c0,0-0.1,0-0.2,0c4.8-5.2,9.5-10.5,14.3-15.7
              c0.7-0.7,1.3-1.5,2-2.2c-1.9,0-3.8,0-5.7,0c3.6-4.4,7.3-8.8,10.9-13.3c0.5-0.6,1.1-1.3,1.6-1.9c-1.7,0-3.5,0-5.2,0
              c3.6-4.8,7.2-9.5,10.9-14.3c0.5-0.7,1-1.4,1.5-2c-1.2,0-2.5,0-3.7,0c3.6-5.4,7.2-10.9,10.9-16.3c0.5-0.8,1-1.5,1.5-2.3
              c-1.5,0-3.1,0-4.6,0c2.1-5,4.2-10.1,6.4-15.1c3.4-8,6.7-15.9,10.1-23.9c0.8-1.8,1.6-3.7,2.3-5.5c2.1,5,4.2,10.1,6.4,15.1
              c3.4,8,6.7,15.9,10.1,23.9c0.8,1.8,1.6,3.7,2.3,5.5c-1.5,0-3.1,0-4.6,0c3.6,5.4,7.2,10.9,10.9,16.3c0.5,0.8,1,1.5,1.5,2.3
              c-1.2,0-2.5,0-3.7,0c3.6,4.8,7.2,9.5,10.9,14.3c0.5,0.7,1,1.4,1.5,2c-1.7,0-3.5,0-5.2,0c3.6,4.4,7.3,8.8,10.9,13.3
              c0.5,0.6,1.1,1.3,1.6,1.9c-1.9,0-3.8,0-5.7,0c4.8,5.2,9.5,10.5,14.3,15.7C1007.9,540.4,1008.6,541.1,1009.2,541.9z"/>
            <path stroke="var(--primary-color)" class="st8" d="M1009.2,541.9c-3.9,0-7.9,0-11.8,0c-7.8,0-15.6,0-23.3,0c-5.6,0-11.3,0-16.9,0c-0.2,0-1,0.1-1.1,0
              c-0.1,0,0-0.2,0-0.3c-0.2-0.9,0-2.1,0-3c0-4.6,0-9.2,0-13.8c0-7,0-13.9,0-20.9c0-8,0-16,0-24c0-7.7,0-15.4,0-23
              c0-6,0-12.1,0-18.1c0-3-0.1-6.1,0-9.1c0-0.1,0-0.3,0-0.4c2.1,5,4.2,10.1,6.4,15.1c3.4,8,6.7,15.9,10.1,23.9
              c0.8,1.8,1.6,3.7,2.3,5.5c-1.5,0-3.1,0-4.6,0c3.6,5.4,7.2,10.9,10.9,16.3c0.5,0.8,1,1.5,1.5,2.3c-1.2,0-2.5,0-3.7,0
              c3.6,4.8,7.2,9.5,10.9,14.3c0.5,0.7,1,1.4,1.5,2c-1.7,0-3.5,0-5.2,0c3.6,4.4,7.3,8.8,10.9,13.3c0.5,0.6,1.1,1.3,1.6,1.9
              c-1.9,0-3.8,0-5.7,0c4.8,5.2,9.5,10.5,14.3,15.7C1007.9,540.4,1008.6,541.1,1009.2,541.9z"/>
          </g>
        </g>
      </g>
      <path stroke="var(--primary-color)" class="st5" d="M1649.8,565.3c0,0.7-1,1-1.5,1.2c-1.3,0.5-2.8,0.8-4.2,1.1c-4.8,0.9-9.8,1.2-14.7,1.4
        c-6.5,0.3-13,0.3-19.5,0.1c-5.2-0.2-10.4-0.5-15.6-1.3c-1.6-0.2-3.3-0.5-4.8-1c-0.7-0.2-1.9-0.5-2.3-1.2c-0.8-1.5,3.6-2.2,4.3-2.4
        c4.6-1,9.3-1.3,13.9-1.6c6.4-0.4,12.8-0.4,19.2-0.3c5.5,0.1,11,0.4,16.4,1.1c1.8,0.2,3.6,0.5,5.4,1c1,0.2,2.2,0.5,3,1.2
        C1649.6,564.8,1649.8,565,1649.8,565.3z"/>
      <g>
        <path stroke="var(--primary-color)" class="st6" d="M1668.3,498.4c-2.3-2.6-5.3,1.2-7,2.7c-3.4,3-6.7,6-10.1,9c-8.1,7.3-16.3,14.6-24.4,21.8
          c-1.9,1.7-3.8,3.4-5.8,5.2c0-5,0-10,0-15.1c0-8.4,0-16.8,0-25.2c0-2.3,0-4.7,0-7c0-1.1-0.2-2.1-1.2-2.8c-1.9-1.3-4.1,0.3-4.1,2.4
          c-0.2,6.3,0,12.7,0,19.1c0,4.2,0,8.4,0,12.5c0,1.6,0,3.1,0,4.7c0,0.8,0.1,1.8,0,2.6c0,0.1,0,0.2,0,0.4
          c-5.5-4.4-11.1-8.8-16.6-13.2c-8.6-6.9-17.2-13.8-25.9-20.6c-1.7-1.4-3.4-2.9-5.2-4.1c-2.8-2-5.9,1.9-3.2,4.2
          c0.6,0.5,1.2,0.9,1.8,1.4c7.5,6,15,12,22.6,18c7.9,6.3,15.8,12.6,23.8,18.9c0.8,0.7,1.7,1.3,2.5,2c0.4,0.4,0.4,0.4,0.4,1.1
          c0,4.8,0,9.6,0,14.5c0,4.3-0.1,8.7,0,13c0,1.1,0.5,2.2,1.6,2.7c1.8,0.8,3.6-0.6,3.7-2.5c0.1-1.6,0-3.2,0-4.9c0-2.9,0-5.9,0-8.8
          c0-1.2,0-2.5,0-3.7c0-0.6-0.2-1.5,0-2.2c0.1-0.4,0.4-0.6,0.8-0.9c1-0.9,2-1.8,2.9-2.6c7.9-7.1,15.8-14.2,23.8-21.3
          c6.3-5.7,12.7-11.4,19-17C1668.9,501.5,1669.6,499.9,1668.3,498.4z"/>
        <g>
          <path stroke="var(--primary-color)" class="st7" d="M1645.7,462.6c0,11.3-7.3,21.7-17.9,25.6c-10.8,3.9-23.1,0.6-30.4-8.3c-7.3-8.8-8.2-21.7-2.3-31.5
            c5.9-9.7,17.5-14.8,28.7-12.6c11,2.2,19.8,11.3,21.6,22.5C1645.6,459.8,1645.7,461.2,1645.7,462.6z"/>
          <path stroke="var(--primary-color)" class="st8" d="M1645.7,462.6c0,11.3-7.2,21.6-17.8,25.6c-3,1.1-6.3,1.7-9.5,1.7c0-6.1,0-12.3,0-18.4c0-9.8,0-19.6,0-29.4
            c0-2.2,0-4.5,0-6.7c11.3,0,21.6,7.2,25.6,17.8C1645.2,456.2,1645.7,459.4,1645.7,462.6z"/>
        </g>
        <g>
          <path stroke="var(--primary-color)" class="st7" d="M1700.3,496.6c0,11.3-7.3,21.7-17.9,25.6c-10.8,3.9-23.1,0.6-30.4-8.3c-7.3-8.8-8.2-21.7-2.3-31.5
            c5.9-9.7,17.5-14.8,28.7-12.6c11,2.2,19.8,11.3,21.6,22.5C1700.2,493.7,1700.3,495.1,1700.3,496.6z"/>
          <path stroke="var(--primary-color)" class="st8" d="M1700.3,496.6c0,11.3-7.2,21.6-17.8,25.6c-3,1.1-6.3,1.7-9.5,1.7c0-6.1,0-12.3,0-18.4c0-9.8,0-19.6,0-29.4
            c0-2.2,0-4.5,0-6.7c11.3,0,21.6,7.2,25.6,17.8C1699.7,490.1,1700.3,493.3,1700.3,496.6z"/>
        </g>
        <g>
          <path stroke="var(--primary-color)" class="st7" d="M1591.1,486.5c0,11.3-7.3,21.7-17.9,25.6c-10.8,3.9-23.1,0.6-30.4-8.3c-7.3-8.8-8.2-21.7-2.3-31.5
            c5.9-9.7,17.5-14.8,28.7-12.6c11,2.2,19.8,11.3,21.6,22.5C1591,483.7,1591.1,485.1,1591.1,486.5z"/>
          <path stroke="var(--primary-color)" class="st8" d="M1591.1,486.5c0,11.3-7.2,21.6-17.8,25.6c-3,1.1-6.3,1.7-9.5,1.7c0-6.1,0-12.3,0-18.4c0-9.8,0-19.6,0-29.4
            c0-2.2,0-4.5,0-6.7c11.3,0,21.6,7.2,25.6,17.8C1590.5,480.1,1591.1,483.3,1591.1,486.5z"/>
        </g>
      </g>
      <path stroke="var(--primary-color)" class="st5" d="M1355.8,565.3c0,0.7,1,1,1.5,1.2c1.3,0.5,2.8,0.8,4.2,1.1c4.8,0.9,9.8,1.2,14.7,1.4c6.5,0.3,13,0.3,19.5,0.1
        c5.2-0.2,10.4-0.5,15.6-1.3c1.6-0.2,3.3-0.5,4.8-1c0.7-0.2,1.9-0.5,2.3-1.2c0.8-1.5-3.6-2.2-4.3-2.4c-4.6-1-9.3-1.3-13.9-1.6
        c-6.4-0.4-12.8-0.4-19.2-0.3c-5.5,0.1-11,0.4-16.4,1.1c-1.8,0.2-3.6,0.5-5.4,1c-1,0.2-2.2,0.5-3,1.2
        C1356,564.8,1355.8,565,1355.8,565.3z"/>
      <g>
        <path stroke="var(--primary-color)" class="st6" d="M1387.2,565.8c2.2,0,2.7-2,2.7-3.7c0-1.5,0-3,0-4.5c0-5.6,0-11.1,0-16.7c0-7,0-13.9,0-20.9
          c0-5.9,0-11.7,0-17.6c0-1.8,0-3.6,0-5.3c0-0.8,0.1-1.8-0.1-2.6c-0.8-2.5-4.5-2.4-5.1,0.1c-0.1,0.4-0.1,0.7-0.1,1.1
          c0,1.2,0,2.5,0,3.7c0,5.3,0,10.5,0,15.8c0,6.9,0,13.8,0,20.7c0,6.1,0,12.2,0,18.4c0,2.9-0.1,5.9,0,8.8
          C1384.6,564.5,1385.6,565.8,1387.2,565.8z"/>
        <path stroke="var(--primary-color)" class="st6" d="M1386.9,543.3c2.2,0,3.4-2.6,2.1-4.3c-0.3-0.4-0.6-0.6-1-0.8c-0.8-0.4-1.6-0.9-2.4-1.3c-3.5-2-7-3.9-10.6-5.9
          c-9.5-5.3-19-10.5-28.5-15.8c-2.9-1.6-5.9-3.3-8.8-4.9c-0.2-0.1-0.9-0.4-1.1-0.6c-0.2-0.4,0-1.7,0-2.1c0-9.6,0-19.1,0-28.7
          c0-4.9,0-9.8,0-14.8c0-2.2,0.2-4.4,0-6.6c-0.2-2.1-2.7-3.1-4.3-1.8c-0.7,0.6-0.9,1.3-1,2.2c0,0.9,0,1.7,0,2.6c0,4.1,0,8.3,0,12.4
          c0,10.6,0,21.1,0,31.7c0,2.2-0.1,4.5,0,6.7c0.1,2.3,3.3,3.4,5,4.4c8.6,4.8,17.2,9.6,25.8,14.3c7.4,4.1,14.9,8.3,22.3,12.4
          C1385.3,542.8,1386.1,543.3,1386.9,543.3z"/>
        <g>
          <path stroke="var(--primary-color)" class="st7" d="M1414,436.9c0,5.6,0,11.3,0,16.9c0,9.6,0,19.1,0,28.7c0,2.9,0,5.9,0,8.8c0,4-1.1,7.8-4.2,10.6
            c-2.3,2-5.3,3.1-8.3,3.1c-0.9,0-1.9,0-2.8,0c-4.5,0-9,0-13.6,0c-3.8,0-7.6,0-11.5,0c-5.3,0-10.2-2.6-12.3-7.6
            c-1.1-2.5-1-5.1-1-7.8c0-3.5,0-7,0-10.6c0-9.7,0-19.4,0-29.1c0-3.5,0-6.9,0-10.4c0-2.8,0-5.5,1.3-8.1c1.5-3,4.1-5.3,7.3-6.4
            c1.6-0.5,3.2-0.7,4.9-0.7c3.9,0,7.9,0,11.8,0c4.5,0,9,0,13.4,0c0.8,0,1.7,0,2.5,0c3.3,0,6.5,1.3,8.9,3.7
            C1412.7,430.4,1414,433.6,1414,436.9z"/>
        </g>
        <path stroke="var(--primary-color)" class="st8" d="M1414,436.9c0,4.6,0,9.2,0,13.7c0,8.7,0,17.4,0,26.1c0,5.2,0,10.5,0,15.7c0,5.1-3.1,9.8-7.9,11.8
          c-3.4,1.3-7.4,0.9-11,0.9c-2,0-4.1,0-6.1,0c-0.3,0-1.6,0.2-1.8-0.1c-0.1-0.2,0-1,0-1.1c0-0.8,0-1.6,0-2.4c0-6.4,0-12.8,0-19.3
          c0-8.8,0-17.6,0-26.5c0-7.6,0-15.2,0-22.8c0-2.2,0-4.5,0-6.7c0-0.4-0.1-1.1,0-1.5c0.1-0.4,0-0.3,0.6-0.3c3.8,0,7.6,0,11.5,0
          c1.4,0,2.8-0.1,4.2,0.1c3,0.5,5.7,2,7.6,4.3C1412.9,431,1414,433.9,1414,436.9z"/>
        <g>
          <path stroke="var(--primary-color)" class="st7" d="M1355.9,425.1c0,4.7,0,9.4,0,14.1c0,8,0,16.1,0,24.1c0,2.4,0,4.9,0,7.3c0,3.4-0.9,6.5-3.5,8.8
            c-1.9,1.7-4.4,2.6-7,2.6c-0.8,0-1.6,0-2.3,0c-3.8,0-7.6,0-11.4,0c-3.2,0-6.4,0-9.6,0c-4.4,0-8.5-2.2-10.2-6.4
            c-0.9-2.1-0.8-4.3-0.8-6.5c0-3,0-5.9,0-8.9c0-8.1,0-16.2,0-24.3c0-2.9,0-5.8,0-8.6c0-2.4,0-4.7,1.1-6.9c2-4,5.9-5.8,10.2-5.8
            c3.3,0,6.6,0,9.9,0c3.7,0,7.4,0,11.1,0c0.7,0,1.4,0,2.1,0c2.8,0,5.4,1.1,7.4,3.1C1354.8,419.7,1355.9,422.4,1355.9,425.1z"/>
        </g>
        <path stroke="var(--primary-color)" class="st8" d="M1355.9,425.1c0,3.8,0,7.6,0,11.5c0,7.3,0,14.6,0,21.9c0,4.4,0,8.7,0,13.1c0,4.3-2.5,8.2-6.5,9.8
          c-2.8,1.2-6.2,0.8-9.2,0.8c-1.7,0-3.4,0-5.2,0c-0.3,0-1.3,0.1-1.5-0.1c-0.1-0.1,0-0.8,0-1c0-0.7,0-1.3,0-2c0-5.4,0-10.8,0-16.2
          c0-7.4,0-14.7,0-22.1c0-6.4,0-12.7,0-19.1c0-1.9,0-3.7,0-5.6c0-0.4-0.1-1,0-1.3c0.1-0.3,0-0.2,0.5-0.2c3.2,0,6.5,0,9.7,0
          c1.1,0,2.3-0.1,3.4,0.1c2.5,0.4,4.8,1.7,6.4,3.6C1355,420.3,1355.9,422.7,1355.9,425.1z"/>
      </g>
      <path stroke="var(--primary-color)" class="st5" d="M1870.8,565.3c0,0.7-1,1-1.5,1.2c-1.3,0.5-2.8,0.8-4.2,1.1c-4.8,0.9-9.8,1.2-14.7,1.4
        c-6.5,0.3-13,0.3-19.5,0.1c-5.2-0.2-10.4-0.5-15.6-1.3c-1.6-0.2-3.3-0.5-4.8-1c-0.7-0.2-1.9-0.5-2.3-1.2c-0.8-1.5,3.6-2.2,4.3-2.4
        c4.6-1,9.3-1.3,13.9-1.6c6.4-0.4,12.8-0.4,19.2-0.3c5.5,0.1,11,0.4,16.4,1.1c1.8,0.2,3.6,0.5,5.4,1c1,0.2,2.2,0.5,3,1.2
        C1870.6,564.8,1870.8,565,1870.8,565.3z"/>
      <g>
        <path stroke="var(--primary-color)" class="st6" d="M1839.5,566.3c-3.5,0-2.7-5.2-2.7-7.5c0-5,0-10,0-15.1c0-11.5,0-23,0-34.5c0-1.6,0-3.1,0-4.7
          c0-0.9-0.1-2,0.3-2.8c1-1.9,3.9-1.8,4.8,0.2c0.2,0.5,0.2,0.9,0.2,1.4c0,1.1,0,2.2,0,3.3c0,10.9,0,21.8,0,32.7c0,5.5,0,11,0,16.5
          c0,2.6,0.1,5.2,0,7.7C1842.1,565.1,1841,566.3,1839.5,566.3z"/>
        <path stroke="var(--primary-color)" class="st6" d="M1841.1,545.3c-4.5,0-9-0.2-13.3-1.5c-4.1-1.3-7.9-3.6-11-6.6c-5.6-5.5-8.6-13-10-20.6
          c-0.4-2.3-0.7-4.7-0.8-7.1c0-0.4-0.1-0.9,0-1.3c0.1-1.9,1.9-3.2,3.7-2.4c1.9,0.8,1.6,3.1,1.7,4.8c0.2,2.8,0.7,5.6,1.4,8.4
          c2,7.7,6.2,15,13.6,18.6c1.9,0.9,4,1.6,6.1,1.9c2.4,0.4,4.7,0.4,7.1,0.5c2,0.1,4,0,6-0.4c8.3-1.5,15-6.9,18.8-14.3
          c2-3.9,3.2-8.2,3.9-12.5c0.2-1.2,0.4-2.5,0.5-3.7c0.1-1.1,0.2-2.1,1.1-2.8c1.6-1.3,4.1-0.3,4.3,1.8c0.1,1.1-0.2,2.5-0.3,3.6
          c-0.2,1.2-0.4,2.4-0.6,3.6c-0.4,2.2-1,4.4-1.7,6.5c-1.3,3.9-3,7.6-5.4,10.8c-5,6.9-12.6,11.4-21.1,12.6
          C1843.9,545.2,1842.5,545.3,1841.1,545.3z"/>
        <path stroke="var(--primary-color)" class="st7" d="M1857,458.7c0,10.4-0.9,20.8-3.2,30.9c-0.9,3.9-2,7.8-3.6,11.5c-1.2,2.8-2.6,5.5-4.6,7.8
          c-1.4,1.5-3.1,2.9-5.2,3.2c-2.1,0.3-4.1-0.7-5.6-2.1c-2-1.8-3.5-4.3-4.7-6.7c-1.6-3.4-2.8-7-3.8-10.6c-2.6-9.7-3.6-19.8-3.9-29.8
          c-0.3-10.6,0.4-21.3,2.4-31.8c1.4-7.1,3.3-14.9,7.6-20.9c2.5-3.5,6.5-6.4,10.7-3.8c2.2,1.4,3.7,3.6,5,5.7c1.7,3,3,6.4,4,9.7
          c2.9,9.3,4.1,19,4.6,28.7C1856.9,453.3,1857,456,1857,458.7z"/>
        <path stroke="var(--primary-color)" class="st8" d="M1857,458.7c0,13.2-1.2,27-5.7,39.6c-1.4,3.9-3.2,8-6.1,11.1c-1,1-2.2,2-3.6,2.4c-0.4,0.2-1.9,0.6-2.2,0.2
          c-0.1-0.1,0-0.7,0-0.9c0-0.6,0-1.2,0-1.7c0-1.9,0-3.8,0-5.7c0-6.1,0-12.2,0-18.3c0-7.9,0-15.8,0-23.7c0-8.1,0-16.2,0-24.3
          c0-6.7,0-13.3,0-20c0-3.7,0-7.5,0-11.2c0-0.2-0.1-0.7,0-0.8c0.3-0.4,1.9,0.1,2.3,0.3c3.1,1.2,5.3,4.3,6.8,7.2
          c2.5,4.6,4,9.6,5.2,14.7c1.5,6.6,2.4,13.4,2.9,20.1C1856.9,451.3,1857,455,1857,458.7z"/>
        <path stroke="var(--primary-color)" class="st7" d="M1818.9,480.4c0,6.1-0.5,12.3-1.9,18.3c-0.9,3.9-2.1,8.4-4.8,11.5c-1.8,2-4.2,2.7-6.4,0.8
          c-2.9-2.5-4.2-6.8-5.1-10.3c-1.6-5.8-2.2-11.9-2.4-17.9c-0.2-6.3,0.2-12.7,1.5-18.9c0.8-4.3,2-8.9,4.6-12.5
          c1.5-2.1,3.8-3.7,6.3-2.2c3,1.9,4.4,6,5.3,9.1c1.7,5.5,2.4,11.2,2.7,16.9C1818.9,477,1818.9,478.7,1818.9,480.4z"/>
        <path stroke="var(--primary-color)" class="st8" d="M1818.9,480.4c0,7.9-0.7,16.1-3.4,23.5c-0.8,2.3-1.9,4.8-3.6,6.6c-0.6,0.6-1.3,1.2-2.1,1.4
          c-0.3,0.1-1.1,0.4-1.3,0.1c-0.2-0.2,0-1.3,0-1.5c0-1.1,0-2.3,0-3.4c0-3.6,0-7.3,0-10.9c0-9.5,0-18.9,0-28.4c0-6.2,0-12.4,0-18.6
          c0-0.1-0.1-0.4,0-0.5c0.2-0.2,1.1,0.1,1.4,0.2c1.9,0.7,3.1,2.6,4,4.2c3.4,6.2,4.3,13.8,4.8,20.7
          C1818.9,476,1818.9,478.2,1818.9,480.4z"/>
        <path stroke="var(--primary-color)" class="st7" d="M1882,480.4c0,6.1-0.5,12.3-1.9,18.3c-0.9,3.9-2.1,8.4-4.8,11.5c-1.8,2-4.2,2.7-6.4,0.8
          c-2.9-2.5-4.2-6.8-5.1-10.3c-1.6-5.8-2.2-11.9-2.4-17.9c-0.2-6.3,0.2-12.7,1.5-18.9c0.8-4.3,2-8.9,4.6-12.5
          c1.5-2.1,3.8-3.7,6.3-2.2c3,1.9,4.4,6,5.3,9.1c1.7,5.5,2.4,11.2,2.7,16.9C1881.9,477,1882,478.7,1882,480.4z"/>
        <path stroke="var(--primary-color)" class="st8" d="M1882,480.4c0,7.9-0.7,16.1-3.4,23.5c-0.8,2.3-1.9,4.8-3.6,6.6c-0.6,0.6-1.3,1.2-2.1,1.4
          c-0.3,0.1-1.1,0.4-1.3,0.1c-0.2-0.2,0-1.3,0-1.5c0-1.1,0-2.3,0-3.4c0-3.6,0-7.3,0-10.9c0-9.5,0-18.9,0-28.4c0-6.2,0-12.4,0-18.6
          c0-0.1-0.1-0.4,0-0.5c0.2-0.2,1.1,0.1,1.4,0.2c1.9,0.7,3.1,2.6,4,4.2c3.4,6.2,4.3,13.8,4.8,20.7C1881.9,476,1882,478.2,1882,480.4
          z"/>
      </g>
      <path stroke="var(--primary-color)" class="st5" d="M104.5,570c0,0.7-1,1-1.5,1.2c-1.3,0.5-2.8,0.8-4.2,1.1c-4.8,0.9-9.8,1.2-14.7,1.4c-6.5,0.3-13,0.3-19.5,0.1
        c-5.2-0.2-10.4-0.5-15.6-1.3c-1.6-0.2-3.3-0.5-4.8-1c-0.7-0.2-1.9-0.5-2.3-1.2c-0.8-1.5,3.6-2.2,4.3-2.4c4.6-1,9.3-1.3,13.9-1.6
        c6.4-0.4,12.8-0.4,19.2-0.3c5.5,0.1,11,0.4,16.4,1.1c1.8,0.2,3.6,0.5,5.4,1c1,0.2,2.2,0.5,3,1.2C104.2,569.5,104.5,569.7,104.5,570
        z"/>
      <g>
        <path stroke="var(--primary-color)" class="st6" d="M73.1,570.5c-2.2,0-2.7-2-2.7-3.7c0-1.5,0-3,0-4.5c0-5.6,0-11.1,0-16.7c0-7,0-13.9,0-20.9
          c0-5.9,0-11.7,0-17.6c0-1.8,0-3.6,0-5.3c0-0.8-0.1-1.8,0.1-2.6c0.8-2.5,4.5-2.4,5.1,0.1c0.1,0.4,0.1,0.7,0.1,1.1
          c0,1.2,0,2.5,0,3.7c0,5.3,0,10.5,0,15.8c0,6.9,0,13.8,0,20.7c0,6.1,0,12.2,0,18.4c0,2.9,0.1,5.9,0,8.8
          C75.7,569.3,74.7,570.5,73.1,570.5z"/>
        <g>
          <path stroke="var(--primary-color)" class="st7" d="M109.9,467.8c0,15.3-9.8,29.4-24.2,34.6c-14.5,5.3-31.2,0.7-41-11.2c-9.8-11.9-11.1-29.3-3.1-42.5
            c8-13.2,23.8-20.1,39-17c14.8,3,26.6,15.3,29,30.3C109.8,463.9,109.9,465.8,109.9,467.8z"/>
          <path stroke="var(--primary-color)" class="st8" d="M109.9,467.8c0,15.3-9.8,29.3-24.1,34.6c-4.1,1.5-8.4,2.3-12.7,2.3c0-2.4,0-4.9,0-7.3c0-5.9,0-11.7,0-17.6
            c0-7.1,0-14.2,0-21.3c0-6.1,0-12.2,0-18.3c0-2,0-4.1,0-6.1c0-0.6,0-1.3,0-1.9c0-0.2-0.1-0.8,0-1c0,0,0-0.1,0-0.1
            c15.3,0,29.3,9.8,34.6,24.1C109.2,459.1,109.9,463.5,109.9,467.8z"/>
        </g>
      </g>
      <path stroke="var(--primary-color)" class="st5" d="M554.8,570c0,0.7-1,1-1.5,1.2c-1.3,0.5-2.8,0.8-4.2,1.1c-4.8,0.9-9.8,1.2-14.7,1.4c-6.5,0.3-13,0.3-19.5,0.1
        c-5.2-0.2-10.4-0.5-15.6-1.3c-1.6-0.2-3.3-0.5-4.8-1c-0.7-0.2-1.9-0.5-2.3-1.2c-0.8-1.5,3.6-2.2,4.3-2.4c4.6-1,9.3-1.3,13.9-1.6
        c6.4-0.4,12.8-0.4,19.2-0.3c5.5,0.1,11,0.4,16.4,1.1c1.8,0.2,3.6,0.5,5.4,1c1,0.2,2.2,0.5,3,1.2C554.6,569.5,554.8,569.7,554.8,570
        z"/>
      <g>
        <path stroke="var(--primary-color)" class="st6" d="M523.5,570.5c-2.2,0-2.7-2-2.7-3.7c0-1.5,0-3,0-4.5c0-5.6,0-11.1,0-16.7c0-7,0-13.9,0-20.9
          c0-5.9,0-11.7,0-17.6c0-1.8,0-3.6,0-5.3c0-0.8-0.1-1.8,0.1-2.6c0.8-2.5,4.5-2.4,5.1,0.1c0.1,0.4,0.1,0.7,0.1,1.1
          c0,1.2,0,2.5,0,3.7c0,5.3,0,10.5,0,15.8c0,6.9,0,13.8,0,20.7c0,6.1,0,12.2,0,18.4c0,2.9,0.1,5.9,0,8.8
          C526.1,569.3,525,570.5,523.5,570.5z"/>
        <g>
          <path stroke="var(--primary-color)" class="st7" d="M547.4,414.8c0,5.4,0,10.7,0,16.1c0,10.5,0,21,0,31.5c0,7.3,0,14.5,0,21.8c0,4.3-1.2,8.3-4.3,11.5
            c-2.5,2.6-5.9,4.1-9.5,4.4c-1.5,0.1-3,0-4.5,0c-4.6,0-9.1,0-13.7,0c-6.3,0-12.1-3.6-14.4-9.6c-1-2.6-1-5.3-1-8
            c0-3.4,0-6.7,0-10.1c0-10.3,0-20.6,0-30.9c0-8.1,0-16.2,0-24.3c0-2.9,0.1-5.8,1.3-8.5c2.6-5.7,8.2-8.7,14.3-8.7c4.7,0,9.3,0,14,0
            c1.6,0,3.2-0.1,4.8,0.1c3.5,0.5,6.8,2.3,9.2,5C546.1,407.8,547.4,411.3,547.4,414.8z"/>
        </g>
        <path stroke="var(--primary-color)" class="st8" d="M547.4,414.8c0,4.9,0,9.8,0,14.7c0,9.9,0,19.8,0,29.7c0,7.7,0,15.5,0,23.2c0,2.4,0.1,4.7-0.6,7
          c-1.9,6.3-7.9,10.5-14.4,10.6c-2.1,0-4.2,0-6.3,0c-0.5,0-2.4,0.3-2.6-0.1c-0.2-0.3,0-1.1,0-1.4c0-0.9,0-1.8,0-2.7
          c0-7.1,0-14.2,0-21.3c0-10,0-20,0-30c0-9.4,0-18.8,0-28.3c0-5.2,0-10.4,0-15.6c0-0.1-0.1-0.6,0-0.7c0.2-0.2,1.7,0,2,0
          c1.2,0,2.5,0,3.7,0c2.1,0,4.3-0.1,6.4,0.3c3.3,0.7,6.3,2.6,8.4,5.2C546.2,408.1,547.4,411.4,547.4,414.8z"/>
      </g>
      <path stroke="var(--primary-color)" class="st5" d="M337.7,570c0,0.7-1,1-1.5,1.2c-1.3,0.5-2.8,0.8-4.2,1.1c-4.8,0.9-9.8,1.2-14.7,1.4c-6.5,0.3-13,0.3-19.5,0.1
        c-5.2-0.2-10.4-0.5-15.6-1.3c-1.6-0.2-3.3-0.5-4.8-1c-0.7-0.2-1.9-0.5-2.3-1.2c-0.8-1.5,3.6-2.2,4.3-2.4c4.6-1,9.3-1.3,13.9-1.6
        c6.4-0.4,12.8-0.4,19.2-0.3c5.5,0.1,11,0.4,16.4,1.1c1.8,0.2,3.6,0.5,5.4,1c1,0.2,2.2,0.5,3,1.2C337.5,569.5,337.7,569.7,337.7,570
        z"/>
      <g>
        <path stroke="var(--primary-color)" class="st6" d="M346,465.4c2.5-2.4-1.2-6-3.8-3.8c-0.3,0.3-0.6,0.6-0.8,0.8c-1.6,1.6-3.3,3.3-4.9,4.9
          c-5.9,5.9-11.8,11.7-17.7,17.6c-6.1,6-12.1,12.1-18.2,18.1c-1.9,1.9-3.8,3.7-5.6,5.6c-0.4,0.4-0.8,1.1-1.3,1.3
          c-0.3,0.2-0.2,0.1-0.6-0.3c-5.3-5-10.6-10.1-16-15.1c-0.2-0.1-0.3-0.3-0.5-0.4c3.5-3.4,7-6.9,10.6-10.3
          c7.6-7.4,15.1-14.8,22.7-22.2c7.2-7.1,14.5-14.2,21.7-21.2c2.1-2,4.2-4.1,6.3-6.1c0.4-0.4,0.9-0.8,1.3-1.3
          c0.4-0.4,0.7-0.8,0.8-1.3c0.6-2.2-1.7-4.1-3.7-3.1c-1.3,0.6-2.4,2.1-3.4,3.1c-2.5,2.4-5,4.9-7.5,7.3c-7.5,7.4-15.1,14.7-22.6,22.1
          c-7.3,7.1-14.6,14.3-21.9,21.4c-2.2,2.1-4.4,4.3-6.6,6.4c-0.4,0.4-0.9,1.1-1.4,1.4c-0.4,0.2-0.4,0-0.8-0.4
          c-3.4-3.2-6.8-6.4-10.2-9.7c-2.8-2.6-5.5-5.4-8.3-7.9c-2.6-2.3-6.1,1.3-3.7,3.8c0.4,0.4,0.8,0.8,1.2,1.1c2.2,2.1,4.5,4.2,6.7,6.4
          c7.7,7.3,15.3,14.5,23,21.8c6.6,6.3,13.3,12.6,19.9,18.9c0.8,0.7,1.6,1.5,2.3,2.2c0.2,0.2,0.6,0.5,0.7,0.7c0.1,0.2,0,0.6,0,0.8
          c0,0.6,0,1.2,0,1.8c0,2,0,4,0,6c0,9.9,0,19.8,0,29.8c0,0.7,0,1.5,0,2.2c0.2,3.5,5.2,3.3,5.3-0.1c0.1-3.1,0-6.3,0-9.4
          c0-9.8,0-19.6,0-29.4c0-0.5-0.2-1.3,0-1.8c0.1-0.2,0.4-0.4,0.6-0.5c0.4-0.4,0.9-0.8,1.3-1.2c3.4-3.1,6.8-6.2,10.2-9.2
          c4-3.7,8.1-7.3,12.1-11c2-1.8,4-3.5,5.9-5.4c2.5-2.5-1-6.1-3.6-3.9c-0.8,0.7-1.5,1.4-2.3,2.1c-3.5,3.2-7.1,6.4-10.6,9.6
          c-3.9,3.6-7.8,7.1-11.8,10.7c-1.2,1.1-2.4,2.2-3.6,3.3c-0.2,0.1-0.5,0.7-0.8,0.7c-0.2,0-0.3-0.1-0.5-0.3c-2.9-2.8-5.8-5.5-8.7-8.3
          c1.6-1.6,3.2-3.2,4.8-4.8c3.9-3.8,7.7-7.7,11.6-11.5c4.7-4.7,9.4-9.3,14.1-14c4.1-4,8.1-8.1,12.2-12.1
          C342,469.4,343.9,467.5,346,465.4C345.9,465.5,346,465.5,346,465.4z"/>
        <g>
          <path stroke="var(--primary-color)" class="st7" d="M376.2,433.1c0,7-5.8,12.7-12.8,12.8c-1.2,0-2.3,0-3.5,0c-6.7,0-13.5,0-20.2,0c-9,0-18,0-27,0
            c-6.9,0-13.8,0-20.8,0c-1.3,0-2.6,0-3.9,0c-5.3,0-10.1-3.2-12-8.2c-2.6-6.8,1.2-14.6,8.1-16.7c2-0.6,3.9-0.6,5.9-0.6
            c6.2,0,12.3,0,18.5,0c8.9,0,17.9,0,26.8,0c7.4,0,14.8,0,22.3,0c1.8,0,3.5,0,5.3,0c3.9,0,7.7,1.5,10.2,4.5
            C375.1,427.1,376.2,430.1,376.2,433.1z"/>
          <path stroke="var(--primary-color)" class="st7" d="M283.2,468.7c0,5.8-4.8,10.4-10.5,10.5c-1,0-1.9,0-2.9,0c-5.5,0-11.1,0-16.6,0c-7.4,0-14.7,0-22.1,0
            c-5.7,0-11.3,0-17,0c-1.1,0-2.2,0-3.2,0c-4.3,0-8.3-2.7-9.9-6.8c-2.1-5.6,1.1-12.1,6.9-13.8c1.5-0.4,3.1-0.4,4.7-0.4
            c5.1,0,10.1,0,15.2,0c7.4,0,14.7,0,22.1,0c6.1,0,12.1,0,18.2,0c1.4,0,2.9,0,4.3,0c3.2,0,6.3,1.2,8.4,3.7
            C282.3,463.8,283.2,466.2,283.2,468.7z"/>
          <path stroke="var(--primary-color)" class="st7" d="M397.1,468.7c0,5.8-4.8,10.4-10.5,10.5c-1,0-1.9,0-2.9,0c-5.5,0-11.1,0-16.6,0c-7.4,0-14.7,0-22.1,0
            c-5.7,0-11.3,0-17,0c-1.1,0-2.2,0-3.2,0c-4.3,0-8.3-2.7-9.9-6.8c-2.1-5.6,1.1-12.1,6.9-13.8c1.5-0.4,3.1-0.4,4.7-0.4
            c5.1,0,10.1,0,15.2,0c7.4,0,14.7,0,22.1,0c6.1,0,12.1,0,18.2,0c1.4,0,2.9,0,4.3,0c3.2,0,6.3,1.2,8.4,3.7
            C396.3,463.8,397.1,466.2,397.1,468.7z"/>
          <path stroke="var(--primary-color)" class="st7" d="M386.5,501.7c0,4.5-3.7,8.1-8.2,8.2c-0.7,0-1.5,0-2.2,0c-4.3,0-8.6,0-12.8,0c-10.2,0-20.4,0-30.6,0
            c-0.8,0-1.7,0-2.5,0c-3.4,0-6.4-2-7.7-5.2c-1.6-4.3,0.7-9.2,5-10.7c1.3-0.4,2.6-0.4,3.9-0.4c9.6,0,19.3,0,28.9,0
            c4.8,0,9.5,0,14.3,0c3.6,0,7.2-0.3,9.9,2.8C385.8,497.8,386.5,499.7,386.5,501.7z"/>
        </g>
        <path stroke="var(--primary-color)" class="st8" d="M376.2,433.1c0,4.6-2.6,9-6.7,11.2c-2.1,1.2-4.5,1.5-6.8,1.5c-4,0-8.1,0-12.1,0c-5.6,0-11.2,0-16.9,0
          c-2,0-4,0-6,0c-0.3,0-1.8,0.2-2,0c-0.1-0.1,0-1,0-1.2c0-0.9,0-1.8,0-2.7c0-2.6,0-5.3,0-7.9c0-2.8,0-5.6,0-8.3c0-1.6-0.2-3.5,0-5
          c0-0.2-0.1-0.3,0.1-0.4c0.2-0.1,0.9,0,1.1,0c1.7,0,3.5,0,5.2,0c5.5,0,11,0,16.4,0c4.5,0,8.9,0,13.4,0c1.4,0,2.8,0,4.2,0.3
          c3.8,0.8,7,3.4,8.7,6.8C375.7,429.2,376.2,431.1,376.2,433.1z"/>
        <path stroke="var(--primary-color)" class="st8" d="M283.2,468.7c0,3.8-2.2,7.4-5.5,9.2c-1.7,0.9-3.6,1.2-5.6,1.2c-3.3,0-6.7,0-10,0c-4.6,0-9.2,0-13.8,0
          c-1.6,0-3.2,0-4.8,0c-0.3,0-1.5,0.2-1.7,0c-0.1-0.1,0-0.9,0-1c0-0.7,0-1.5,0-2.2c0-2.2,0-4.4,0-6.5c0-2.3,0-4.6,0-6.8
          c0-0.9,0-1.7,0-2.6c0-0.4-0.1-1.1,0-1.5c0.1-0.2-0.1-0.2,0.1-0.3c0.2-0.1,0.8,0,0.9,0c1.4,0,2.9,0,4.3,0c4.5,0,9,0,13.5,0
          c3.6,0,7.3,0,10.9,0c1.1,0,2.3,0,3.4,0.2c3.1,0.7,5.8,2.7,7.2,5.6C282.8,465.4,283.2,467,283.2,468.7z"/>
        <path stroke="var(--primary-color)" class="st8" d="M397.1,468.7c0,3.8-2.2,7.4-5.5,9.2c-1.7,0.9-3.6,1.2-5.6,1.2c-3.3,0-6.7,0-10,0c-4.6,0-9.2,0-13.8,0
          c-1.6,0-3.2,0-4.8,0c-0.3,0-1.5,0.2-1.7,0c-0.1-0.1,0-0.9,0-1c0-0.7,0-1.5,0-2.2c0-2.2,0-4.4,0-6.5c0-2.3,0-4.6,0-6.8
          c0-0.9,0-1.7,0-2.6c0-0.4-0.1-1.1,0-1.5c0.1-0.2-0.1-0.2,0.1-0.3c0.2-0.1,0.8,0,0.9,0c1.4,0,2.9,0,4.3,0c4.5,0,9,0,13.5,0
          c3.6,0,7.3,0,10.9,0c1.1,0,2.3,0,3.4,0.2c3.1,0.7,5.8,2.7,7.2,5.6C396.8,465.4,397.1,467,397.1,468.7z"/>
        <path stroke="var(--primary-color)" class="st8" d="M386.5,501.7c0,4.8-4,8.2-8.6,8.2c-2.5,0-5.1,0-7.6,0c-3.6,0-7.2,0-10.8,0c-1.3,0-2.6,0-3.8,0
          c-0.2,0-1.3,0.2-1.4,0c-0.3-0.3,0-2,0-2.4c0-1.7,0-3.3,0-5c0-1.8,0-3.6,0-5.3c0-0.7,0-1.4,0-2.1c0-0.4-0.1-0.9,0-1.2
          c0.1-0.4,0.2-0.2,0.7-0.2c1.1,0,2.2,0,3.3,0c3.5,0,7,0,10.5,0c2.9,0,5.7,0,8.6,0c3.5,0,6.7,1.2,8.3,4.5
          C386.2,499.2,386.5,500.4,386.5,501.7z"/>
      </g>
    </g>
      </svg>
    </div>
  );
};

export default memo(AnimationPathTreeDay);
