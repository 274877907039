import { Link } from 'gatsby';
import React, { memo, useState, useEffect } from 'react';
import { ThemeToggler } from 'gatsby-plugin-dark-mode';
import { IoIosCloudyNight } from 'react-icons/io';
import cs from 'classnames';
import { Image, JobSearch } from '@components';
import { routes } from '@appConstants';
import './header.scss';

const Header = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [menuState, setMenuState] = useState(false);
  
  useEffect(() => { if (typeof window !== 'undefined') {
    window.onscroll = function() {
      let currentScrollPos = window.pageYOffset;
      setPrevScrollPos(currentScrollPos);
      setMenuState(false);
    };
  } }, []);

  return (
    <header className={cs('on-title-header', prevScrollPos > 0 ? 'on-title-header-scrolled' : '')}>
      <div className="on-title-logo logo">
        <Link to={routes.home}>
          <div className="on-title-logo">
            <Image filename="ooden_textual_color_dark.png" />
          </div>
        </Link>
      </div>
      <input className="menu-btn" type="checkbox" id="menu-btn" checked={menuState} />
      <label className="menu-icon" onClick={()=>setMenuState(!menuState)}>
        <span className="navicon"></span>
      </label>
      <JobSearch className={prevScrollPos > 0 ? 'on-title-header-job-scrolled' : 'on-title-header-job'}/> 
      <ul className="menu">
        <li className="menu-button" onClick={()=>setMenuState(false)}>
          <Link to={routes.blog}>
            <p className="on-menu-item">blog</p>
          </Link>
        </li>
       {/* <li>
          <Link to={routes.about}>
            <p className="on-menu-item">about</p>
          </Link>
       </li>*/}
      </ul>
      <div >
        <p className="on-header-dark-mode on-menu-item-checkbox" htmlFor="darkModeToggle">
          <ThemeToggler  className="on-header-dark-mode on-menu-item-checkbox" >
            {({ theme, toggleTheme }) => (
              <label >
                <input
                  id="darkModeToggle"
                  type="checkbox"
                  onChange={e => toggleTheme(e.target.checked ? 'light' : 'dark')}
                  checked={theme === 'light'}
                />
                <IoIosCloudyNight className='icon-container' />
              </label>
            )}
          </ThemeToggler>
        </p>
      </div>
    </header>
  );
};
export default memo(Header);
