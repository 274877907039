import React, { memo, useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';
import './landing-animation.scss';

const AnimationPath = () => {
  useEffect(() => {
    let pathEls = document.querySelectorAll('path.on-path-base');
    for (let i = 0; i < pathEls.length; i++) {
      let pathEl = pathEls[i];
      let offset = anime.setDashoffset(pathEl);
      pathEl.setAttribute('stroke-dashoffset', offset);
      let anim = anime({
        targets: pathEl,
        strokeDashoffset: [offset, 0],
        duration: anime.random(5000, 9000),
        delay: anime.random(200, 2000),
        loop: true,
        direction: 'alternate',
        easing: 'easeInOutBack',
        translateX: [8, -8],
        translateY: [-8, 8],
        autoplay: true,
      });
      anim.play();
    }
  }, []);

  return (
    <div className="anim">
      <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin slice " fill="var(--bg-color)" viewBox="0 0 1920 1080" width="100%" >
        <path className="on-path-base" stroke="var(--primary-color)" d="M-44.5,166.4H79.7l35.55,35.55v88.65l39.82,39.82h53.32"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M54.5,207.8v91.8l-27.45,27.45H-65.2"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M346.1,406.7H158.9l-29.7,29.7h-72l-78.3-78.3"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M-95.8,534.5H81.95l52.43-52.42h39.82l20.76,20.76H354.2"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M536.9,182.08h-64.8l-18.9,18.9h-77.63L331.7,157.1h-94.95L162.5,82.85"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M743.9,187.7H628.25l-37.35-37.35h-32.4"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M1149.8,124.25h107.1l30.6,30.6h36"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M877.1,396.35l-71.1,71.1h-64.35L689,520.1"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M311.9,458.23h105.3l26.66-26.66H545l25.03,25.03v77.91l-25.57,25.57v47.33"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M496.4,485.9l-60.57,60.57v69.03l-24.17,24.17H293"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M18.5,680.3L123.8,575h222.3l25.65-25.65"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M570.03,653.3H680l40.39-40.39H835.7l17.61-17.61h63.39l17.41,17.41h40.19"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M689,562.78"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M968.29,458.23v47.65L941,533.17H812.67l-29.62,29.62H644"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M920.3,476v-36l24.75-24.75h116.55l21.58,21.58V476"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M1219.1,556.27h-135.62l-47.98-47.98V463.4"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M1347.8,92.75h117.9l27.9,27.9h63.9l15.53-15.53h135.67l24.41,24.41"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M1373,219.25"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M2081.3,200.98h-233.37l-72.63,72.63h-121.39l-39.71-39.71"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M1957.1,263.6l-81.9,81.9h-60.3l-55.13,55.13H1562.9"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M1138.1,510.5l68.8-68.8H1337l28.85,28.85h91.75"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M1323.5,396.35h45l20.93,20.92h108.67l41.65,41.65v61.17"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M1632.2,554.34h-126.35l-32.05,32.05h-120.29l-66.91-66.91"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M2004.8,462.41h-223.51l-25.79,25.79h-127.3l-51.8-51.8"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M1662.8,629l54.45-54.45h95.85l38.88-38.88h185.22"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M1972.4,26l-111.23,111.23h-45.37l-51.68,51.68h-53.62l-21.19-21.19h-57.11"/>
        <path className="on-path-base" stroke="var(--primary-color)" d="M-44.5,0L57.68,102.18h51.72l65.38,65.38v87.95l19.23,19.23"/>
      
        </svg>
    </div>
  );
};

export default memo(AnimationPath);
