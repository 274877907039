import React, { memo, useState } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import './switch.scss';

const Switch = props => {
  const { className, style, id } = props;
  const [checked, setChecked] = useState(false);
  const handleClick = () => setChecked(!checked);

  return (
    <div>
      <input
        id={id}
        type="checkbox"
        className={cs('switch', className)}
        style={style}
        onClick={handleClick}
        checked={checked}
      />
    </div>
  );
};

Switch.PropTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

Switch.defaultProps = {
  className: '',
  id: '',
  style: {},
};

export default memo(Switch);
