export const employeeList = [
  {
    id: 1,
    imageUrl: '/employees/vucak_01.jpg',
    link: 'https://github.com/ivucak',
  },
  {
    id: 2,
    imageUrl: '/employees/maric_01.jpg',
    link: 'https://github.com/ratep1',
  },
];
