
import { FaFacebookF, FaGithub, FaTwitter, FaInstagram, FaPhone, FaMailBulk } from 'react-icons/fa';

export const social = [
  { id: 0, name: 'Phone', link: 'tel:+385912353768', icon:<FaPhone  className="social-icons"/> },
  { id: 2, name: 'Email', link: 'mailto:info@ooden.io', icon:<FaMailBulk className="social-icons"/>},
  { id: 4, name: 'Phone', link: 'tel:+15854570020', icon:<FaPhone  className="social-icons"/> },
  { id: 1, name: 'Facebook', link: '/', icon:<FaFacebookF  className="social-icons"/> },
  { id: 3, name: 'Twitter', link: '/', icon:<FaTwitter  className="social-icons"/> },
  { id: 5, name: 'GitHub', link: '/', icon:<FaGithub className="social-icons"/>},
];
