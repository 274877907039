import React from 'react';
import { TransitionGroup, Transition as ReactTransition } from 'react-transition-group';
import { transitionStyles, transitionTimeout } from '@appConstants';

class Transition extends React.PureComponent {
  render() {
    const { children, location } = this.props;

    return (
      <TransitionGroup>
        <ReactTransition
          key={location.pathname}
          timeout={{
            enter: transitionTimeout,
            exit: transitionTimeout,
          }}
        >
          {status => (
            <div
              style={{
                ...transitionStyles[status],
              }}
            >
              {children}
            </div>
          )}
        </ReactTransition>
      </TransitionGroup>
    );
  }
}

export default Transition;
