import { routes } from '../app-constants/endpoint-constants';

export const oodenSAS = [
  {
    id: 1,
    title: 'Scalability',
    text:
      'Our expert software engineers can help you plan,\
     design, implement and monitor software systems \
     that will help you archive your business goals.',
    gradientStyle: {
      background: 'linear-gradient(135deg, var(--gradient-1-top) 0%,var(--gradient-1-bottom) 100%)',
    },
  },
  {
    id: 2,
    title: 'Expertise',
    text:
      'Developing well-engineered, scalable software calls for a high level\
     of skill. We have implemented a demanding hiring process\
     and employ only the best applicants.',
    gradientStyle: {
      background: 'linear-gradient(135deg, var(--gradient-2-top) 0%,var(--gradient-2-bottom) 100%)',
    },
  },
  {
    id: 3,
    title: 'Reliability',
    text:
      'We have never compromised on quality and we will never have to \
    thanks to our professional and highly trained staff.',
    gradientStyle: {
      background: 'linear-gradient(135deg, var(--gradient-3-top) 0%,var(--gradient-3-bottom) 100%)',
    },
  },
  {
    id: 4,
    title: 'Strategy',
    text:
      'We study your business needs and establish an approach that will \
    make sure we achieve your objectives. We employ best practice throughout all development stages, \
    ensuring that all decisions are durable and stable in both the short-term and long-term.',
    gradientStyle: {
      background: 'linear-gradient(135deg, var(--gradient-4-top) 0%,var(--gradient-4-bottom) 100%)',
    },
  },
];
