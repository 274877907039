import PropTypes from 'prop-types';
import React, {memo} from 'react';
import { Image, Card } from '@components';
import { testimonials } from '@content';
import './landing-testimonials.scss';

const TestimonialCard = props => {
  const { image, author, company, text } = props;

  return (
    <Card className="on-inverted on-no-animation-inverted">
      <div className="on-testimonial-card-container">
        <div className="on-testimonial-card-container-grid-image">
          <Image className="on-testimonial-card-container-image" filename={image} />
        </div>
        <div className="on-testimonial-card-container-grid-author">
          <h3>{author}</h3>
          <h3>{company}</h3>
        </div>
        <div className="on-testimonial-card-container-grid-text">{text}</div>
      </div>
    </Card>
  );
};

TestimonialCard.propTypes = {
  author: PropTypes.string,
  company: PropTypes.string,
  image: PropTypes.string,
  text: PropTypes.string,
};

TestimonialCard.defaultProps = {
  author: '',
  company: '',
  image: '',
  text: '',
};

const LandingTestimonials = () => {
  return (
    <div className="on-testimonial-container">
      <h2>Testimonials </h2>
      <div className="on-testimonial-items-container">
        {testimonials.map(item => (
          <TestimonialCard
            key={item.id}
            author={item.author}
            company={item.company}
            text={item.text}
            image={item.imageUrl}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(LandingTestimonials);
