import React, {memo} from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import './input-field.scss';

const InputField = props => {
  const { name, text, rows, id, type} = props;
  const { className, style } = props;

  return (
    <div className="on-input-field-container">
      <label htmlFor={name}>{text}</label>
      {!rows ? (
        <input
          className={cs('on-input-field-base', className)}
          style={style}
          type={type ? type : "text"}
          id={id}
          name={name}
        />
      ) : (
        <textarea
          rows={rows}
          className={cs('on-input-area-base', className)}
          style={style}
          type="text"
          id={id}
          name={name}
        />
      )}
    </div>
  );
};

InputField.PropTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  rows: PropTypes.number,
  type: PropTypes.string
};

InputField.defaultProps = {
  className: '',
  style: {},
  id: '',
  name: '',
  text: '',
  rows: null,
};

export default memo(InputField);
