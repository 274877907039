import React, { memo, useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';
import '../landing-animation.scss';
import AnimationPath from '../animation-path';

const AnimationPathSW = () => {
  useEffect(() => {
    let pathEls = document.querySelectorAll('path.on-ee');
    for (let i = 0; i < pathEls.length; i++) {
      let pathEl = pathEls[i];
      let offset = anime.setDashoffset(pathEl);
      pathEl.setAttribute('stroke-dashoffset', offset);
      let anim = anime({
        targets: pathEl,
        strokeDashoffset: [offset, 0],
        duration: anime.random(5000, 6000),
        delay: anime.random(200, 200),
        loop: false,
        direction: 'alternate',
        easing: 'easeInOutBack',
        autoplay: true,
      });
      anim.play();
    }

    pathEls = document.querySelectorAll('path.on-ee-2');
    for (let i = 0; i < pathEls.length; i++) {
      let pathEl = pathEls[i];
      let offset = anime.setDashoffset(pathEl);
      pathEl.setAttribute('stroke-dashoffset', offset);
      let anim = anime({
        targets: pathEl,
        strokeDashoffset: [offset, 1],
        duration: anime.random(7000, 10000),
        delay: anime.random(200, 4000),
        loop: true,
        direction: 'alternate',
        easing: 'easeInOutBack',
        autoplay: true,
      });
      anim.play();
    }
  }, []);

  return (
    <div>
      <AnimationPath />
      <div className="anim">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMinYMin slice "
          fill="var(--bg-color)"
          viewBox="0 0 1920 1080"
          width="100%"
        >
          {' '}
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1334.8,283.6c-6.4-38.2,6.6-79,33-107.3c24.5-27,60.7-43.1,97.2-42.6"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1338.1,280.3c8.1,0.2,16.2-0.2,24.3,0.2c2.4,0.9,0.8,4.5-1.5,3.8c-8.6-0.5-17.8,0.9-26-0.7"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1337.2,263.2c85,0.1,169.9,0.1,254.9,0c0-4.4-0.3-8.7-0.8-13c-31.9-0.2-63.9,0.1-95.8-0.1
    c-2.7,0.3-2.7-4.2,0-3.9c32.2-0.1,64.4,0,96.5,0c1.3-0.2,2.9,0.3,3,1.9c1.5,11.4,1.5,23.1,0,34.5c-0.1,1.2-1.3,1.9-2.4,1.7
    c-22.5,0-45.1,0-67.6,0c-2.6,0.3-2.7-4.2,0-3.9c22.1-0.1,44.2,0,66.4,0c0.5-4.4,1.1-8.8,0.7-13.2c-84.8,0.1-169.7,0.1-254.5,0"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1462.6,137.6c-36.2,0.2-71.8,17.3-95.1,44.9c-19,22.3-30.2,51.3-30.3,80.6"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1464.9,133.7c3.4,1.6,0.7,6.7,1.8,9.8c11.4,0,22.8-0.1,34.2,0c2.7-0.3,2.5,4.1-0.1,3.8
    c-15.8-0.1-31.8,0.4-47.5-0.3c-3-5.3,6.2-3.3,9.2-3.6c0.3-1.9,0.2-3.9,0.1-5.8"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1456.7,164.4c-2.5,0.2-2.5-4,0-3.8c23.1-0.2,46.2-0.1,69.3,0c2.2-0.4,3.3,2.6,1.4,3.7
    c-4.4,0.6-8.9,0-13.3,0.3c0,4.3,0,8.6,0,13c6.1,0.4,12.2-0.1,18.2,0.2c2.3,0.2,2,4.2-0.4,3.9c-14.9,0-29.7,0-44.6,0
    c-2.3,0-2.4-4,0-3.9c7.6-0.2,15.2,0.1,22.7-0.2c0-4.3,0-8.7,0-13C1492.3,164.4,1474.5,164.6,1456.7,164.4z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1405.7,175c18.6-1.6,36.3,14.3,36.7,33c1.2,17.6-13.2,34.5-30.9,35.9c-18.6,2.5-37.1-12.8-38.1-31.5
      C1371.3,193.8,1387,175.7,1405.7,175z M1402.4,179.4c-10.1,1.8-19,9-22.9,18.5c-7.5,16.8,3.7,38.2,21.7,41.6
      c18.1,4.6,37.4-10.7,37.2-29.3C1439.4,191.6,1420.7,175.5,1402.4,179.4z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1460.6,198.6c-2.2-0.3-1.8-3.9,0.5-3.8c18.4-0.1,36.7-0.1,55.1,0c2.3-0.5,3.1,3.2,0.9,3.8
    c-7.2,0.3-14.4-0.1-21.5,0.2c0,4.4-0.1,8.7,0,13.1c10.3,0.5,20.6-0.3,30.9,0.2c2.8-0.2,2.2,4.5-0.5,3.8
    c-14.1-0.2-28.3,0.5-42.3-0.4c-2.8-4.8,5.1-3.3,7.8-3.6c0-4.4,0.1-8.7,0-13.1C1481.2,198.4,1470.9,198.8,1460.6,198.6z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1529.4,195.1c7.3-0.8,14.8-0.2,22.1-0.3c2.4-0.1,2.4,4,0,3.8c-7,0.1-14.1,0.1-21.1,0
    C1528.6,198.7,1528.3,196.1,1529.4,195.1z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1404.6,195.7c9.9-2.8,20.1,7.7,17,17.5c-2.2,10-16,14.2-23.3,6.9C1389.7,213.1,1393.7,197.7,1404.6,195.7z
       M1405.7,199.5c-7.5,1.2-10.8,11.6-5.4,17c4.7,5.6,14.7,3.7,17.1-3.2C1420.6,206.1,1413.3,197.4,1405.7,199.5z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1498.4,228.9c5.3,0.1,10.6-0.1,15.9,0.1c2.1,0.3,2.1,3.5,0,3.8c-13.7,0.4-27.5,0-41.2,0
    c-2.5,0.3-2.6-4-0.1-3.9C1481.5,228.9,1489.9,229.1,1498.4,228.9z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1531.6,229c13.1-0.1,26.3-0.1,39.4,0c2.3-0.1,2.4,3.8,0.1,3.9c-13,0.1-26.1,0.1-39.1,0
    C1529.7,233.1,1529.3,229.3,1531.6,229z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1472.2,280.6c8-0.7,16.3-0.2,24.4-0.3c2.6-0.2,2.5,4.1,0,3.9c-7.8,0-15.6,0.1-23.4,0
    C1471.2,284.4,1470.5,281.4,1472.2,280.6z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1348.8,318.6c14.4,31.6,42.4,56.6,75.3,67.6c25,8.3,52.9,9.1,77.9,0.8c-6.6-0.1-13.1,0.2-19.7-0.1
    c-2.2-0.4-1.7-4,0.5-3.8c10.3-0.3,20.7,0.2,31-0.2c8.2-3.4,16.2-7.5,23.1-12.9c-16.8-0.4-33.7,0.3-50.5-0.3
    c-3.4-5.5,6.7-3.3,9.8-3.7c0.2-4.4,0.2-8.8,0-13.2c-6.3-0.1-12.6,0.1-18.8-0.1c-2.4,0.1-2.4-4,0-3.8c13-0.1,26.1-0.1,39.1,0
    c2.3-0.1,2.4,3.8,0.1,3.8c-5.4,0.2-10.8,0-16.1,0.1c-0.3,4.4-0.1,8.8-0.1,13.2c14.3,0,28.6,0,42.9,0c1.8-0.3,2.9,2.2,1.5,3.3
    c-31.3,24.9-74.5,33.4-113.1,23.4c-45-11.2-82.5-48.2-94.1-93.1"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1347.1,314.5c7,0.1,14-0.1,21,0.1c2.1,0.1,2.1,3.5,0.1,3.8c-6.5,0.3-12.9-0.1-19.4,0.2"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1341.7,297.4c11.1,0.1,22.2-0.1,33.3,0.1c2.4-0.5,3,3.8,0.5,3.8c-11,0.2-22.1,0-33.2,0.1"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1386.6,297.6c4.8-0.5,9.7-0.3,14.5-0.1c2.2-0.1,2.6,3.3,0.5,3.8c-4.7,0.2-9.4,0.1-14.1,0.1
    C1385.3,301.6,1384.6,298.3,1386.6,297.6z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1453.4,301.4c-2.7,0.4-2.7-4.3,0-3.9c18-0.2,36-0.1,54,0c2.3-0.5,3.2,3.2,1,3.8c-4.6,0.4-9.3,0-14,0.2
    c-0.1,4.3,0,8.7,0,13c19,0.3,38.1,0,57.1,0.2c2.2,0.1,2.2,3.8,0,3.9c-26.3,0.2-52.6-0.1-78.9,0.2c0,4.3-0.1,8.7,0,13
    c10.5,0.1,21-0.1,31.5,0.1c2.6-0.2,2.6,4.1,0,3.9c-14.9,0-29.8,0.1-44.7,0c-2.4,0.6-3.4-3.3-1-3.8c3.4-0.4,6.8,0,10.1-0.1
    c0.1-4.3,0-8.6,0-13c-18.5-0.4-36.9,0-55.4-0.2c-2.2,0-2.2-3.8,0-3.9c25.7-0.2,51.5,0.2,77.2-0.2c0-4.3,0.1-8.7,0-13
    C1478.1,301.4,1465.7,301.5,1453.4,301.4z"
          />
          <path
            className="on-ee"
            stroke="var(--primary-color)"
            d="M1444.9,348.9c6.8-0.2,13.6-0.1,20.4-0.1c2.4,0.1,2,4.2-0.4,3.8c-6.5,0-13.1,0.1-19.6-0.1
    C1443.3,352.6,1443.1,349.3,1444.9,348.9z"
          />
          <path
            className="on-ee-2"
            stroke="var(--primary-color)"
            d="M1334.8,283.6c-6.4-38.2,6.6-79,33-107.3c24.5-27,60.7-43.1,97.2-42.6"
          />
          <path
            className="on-ee-2"
            stroke="var(--primary-color)"
            d="M1338.1,280.3c8.1,0.2,16.2-0.2,24.3,0.2c2.4,0.9,0.8,4.5-1.5,3.8c-8.6-0.5-17.8,0.9-26-0.7"
          />
          <path
            className="on-ee-2"
            stroke="var(--primary-color)"
            d="M1337.2,263.2c85,0.1,169.9,0.1,254.9,0c0-4.4-0.3-8.7-0.8-13c-31.9-0.2-63.9,0.1-95.8-0.1
        c-2.7,0.3-2.7-4.2,0-3.9c32.2-0.1,64.4,0,96.5,0c1.3-0.2,2.9,0.3,3,1.9c1.5,11.4,1.5,23.1,0,34.5c-0.1,1.2-1.3,1.9-2.4,1.7
        c-22.5,0-45.1,0-67.6,0c-2.6,0.3-2.7-4.2,0-3.9c22.1-0.1,44.2,0,66.4,0c0.5-4.4,1.1-8.8,0.7-13.2c-84.8,0.1-169.7,0.1-254.5,0"
          />
          <path
            className="on-ee-2"
            stroke="var(--primary-color)"
            d="M1462.6,137.6c-36.2,0.2-71.8,17.3-95.1,44.9c-19,22.3-30.2,51.3-30.3,80.6"
          />
          <path
            className="on-ee-2"
            stroke="var(--primary-color)"
            d="M1464.9,133.7c3.4,1.6,0.7,6.7,1.8,9.8c11.4,0,22.8-0.1,34.2,0c2.7-0.3,2.5,4.1-0.1,3.8
        c-15.8-0.1-31.8,0.4-47.5-0.3c-3-5.3,6.2-3.3,9.2-3.6c0.3-1.9,0.2-3.9,0.1-5.8"
          />
          <path
            className="on-ee-2"
            stroke="var(--primary-color)"
            d="M1456.7,164.4c-2.5,0.2-2.5-4,0-3.8c23.1-0.2,46.2-0.1,69.3,0c2.2-0.4,3.3,2.6,1.4,3.7
        c-4.4,0.6-8.9,0-13.3,0.3c0,4.3,0,8.6,0,13c6.1,0.4,12.2-0.1,18.2,0.2c2.3,0.2,2,4.2-0.4,3.9c-14.9,0-29.7,0-44.6,0
        c-2.3,0-2.4-4,0-3.9c7.6-0.2,15.2,0.1,22.7-0.2c0-4.3,0-8.7,0-13C1492.3,164.4,1474.5,164.6,1456.7,164.4z"
          />
          <path
            className="on-ee-2"
            stroke="var(--primary-color)"
            d="M1405.7,175c18.6-1.6,36.3,14.3,36.7,33c1.2,17.6-13.2,34.5-30.9,35.9c-18.6,2.5-37.1-12.8-38.1-31.5
          C1371.3,193.8,1387,175.7,1405.7,175z M1402.4,179.4c-10.1,1.8-19,9-22.9,18.5c-7.5,16.8,3.7,38.2,21.7,41.6
          c18.1,4.6,37.4-10.7,37.2-29.3C1439.4,191.6,1420.7,175.5,1402.4,179.4z"
          />
          <path
            className="on-ee-2"
            stroke="var(--primary-color)"
            d="M1460.6,198.6c-2.2-0.3-1.8-3.9,0.5-3.8c18.4-0.1,36.7-0.1,55.1,0c2.3-0.5,3.1,3.2,0.9,3.8
        c-7.2,0.3-14.4-0.1-21.5,0.2c0,4.4-0.1,8.7,0,13.1c10.3,0.5,20.6-0.3,30.9,0.2c2.8-0.2,2.2,4.5-0.5,3.8
        c-14.1-0.2-28.3,0.5-42.3-0.4c-2.8-4.8,5.1-3.3,7.8-3.6c0-4.4,0.1-8.7,0-13.1C1481.2,198.4,1470.9,198.8,1460.6,198.6z"
          />
          <path
            className="on-ee-2"
            stroke="var(--primary-color)"
            d="M1529.4,195.1c7.3-0.8,14.8-0.2,22.1-0.3c2.4-0.1,2.4,4,0,3.8c-7,0.1-14.1,0.1-21.1,0
        C1528.6,198.7,1528.3,196.1,1529.4,195.1z"
          />
          <path
            className="on-ee-2"
            stroke="var(--primary-color)"
            d="M1404.6,195.7c9.9-2.8,20.1,7.7,17,17.5c-2.2,10-16,14.2-23.3,6.9C1389.7,213.1,1393.7,197.7,1404.6,195.7z
           M1405.7,199.5c-7.5,1.2-10.8,11.6-5.4,17c4.7,5.6,14.7,3.7,17.1-3.2C1420.6,206.1,1413.3,197.4,1405.7,199.5z"
          />
          <path
            className="on-ee-2"
            stroke="var(--primary-color)"
            d="M1498.4,228.9c5.3,0.1,10.6-0.1,15.9,0.1c2.1,0.3,2.1,3.5,0,3.8c-13.7,0.4-27.5,0-41.2,0
        c-2.5,0.3-2.6-4-0.1-3.9C1481.5,228.9,1489.9,229.1,1498.4,228.9z"
          />
          <path
            className="on-ee-2"
            stroke="var(--primary-color)"
            d="M1531.6,229c13.1-0.1,26.3-0.1,39.4,0c2.3-0.1,2.4,3.8,0.1,3.9c-13,0.1-26.1,0.1-39.1,0
        C1529.7,233.1,1529.3,229.3,1531.6,229z"
          />
          <path
            className="on-ee-2"
            stroke="var(--primary-color)"
            d="M1472.2,280.6c8-0.7,16.3-0.2,24.4-0.3c2.6-0.2,2.5,4.1,0,3.9c-7.8,0-15.6,0.1-23.4,0
        C1471.2,284.4,1470.5,281.4,1472.2,280.6z"
          />
          <path
            className="on-ee-2"
            stroke="var(--primary-color)"
            d="M1348.8,318.6c14.4,31.6,42.4,56.6,75.3,67.6c25,8.3,52.9,9.1,77.9,0.8c-6.6-0.1-13.1,0.2-19.7-0.1
        c-2.2-0.4-1.7-4,0.5-3.8c10.3-0.3,20.7,0.2,31-0.2c8.2-3.4,16.2-7.5,23.1-12.9c-16.8-0.4-33.7,0.3-50.5-0.3
        c-3.4-5.5,6.7-3.3,9.8-3.7c0.2-4.4,0.2-8.8,0-13.2c-6.3-0.1-12.6,0.1-18.8-0.1c-2.4,0.1-2.4-4,0-3.8c13-0.1,26.1-0.1,39.1,0
        c2.3-0.1,2.4,3.8,0.1,3.8c-5.4,0.2-10.8,0-16.1,0.1c-0.3,4.4-0.1,8.8-0.1,13.2c14.3,0,28.6,0,42.9,0c1.8-0.3,2.9,2.2,1.5,3.3
        c-31.3,24.9-74.5,33.4-113.1,23.4c-45-11.2-82.5-48.2-94.1-93.1"
          />
          <path
            className="on-ee-2"
            stroke="var(--primary-color)"
            d="M1347.1,314.5c7,0.1,14-0.1,21,0.1c2.1,0.1,2.1,3.5,0.1,3.8c-6.5,0.3-12.9-0.1-19.4,0.2"
          />
          <path
            className="on-ee-2"
            stroke="var(--primary-color)"
            d="M1341.7,297.4c11.1,0.1,22.2-0.1,33.3,0.1c2.4-0.5,3,3.8,0.5,3.8c-11,0.2-22.1,0-33.2,0.1"
          />
          <path
            className="on-ee-2"
            stroke="var(--primary-color)"
            d="M1386.6,297.6c4.8-0.5,9.7-0.3,14.5-0.1c2.2-0.1,2.6,3.3,0.5,3.8c-4.7,0.2-9.4,0.1-14.1,0.1
        C1385.3,301.6,1384.6,298.3,1386.6,297.6z"
          />
          <path
            className="on-ee-2"
            stroke="var(--primary-color)"
            d="M1453.4,301.4c-2.7,0.4-2.7-4.3,0-3.9c18-0.2,36-0.1,54,0c2.3-0.5,3.2,3.2,1,3.8c-4.6,0.4-9.3,0-14,0.2
        c-0.1,4.3,0,8.7,0,13c19,0.3,38.1,0,57.1,0.2c2.2,0.1,2.2,3.8,0,3.9c-26.3,0.2-52.6-0.1-78.9,0.2c0,4.3-0.1,8.7,0,13
        c10.5,0.1,21-0.1,31.5,0.1c2.6-0.2,2.6,4.1,0,3.9c-14.9,0-29.8,0.1-44.7,0c-2.4,0.6-3.4-3.3-1-3.8c3.4-0.4,6.8,0,10.1-0.1
        c0.1-4.3,0-8.6,0-13c-18.5-0.4-36.9,0-55.4-0.2c-2.2,0-2.2-3.8,0-3.9c25.7-0.2,51.5,0.2,77.2-0.2c0-4.3,0.1-8.7,0-13
        C1478.1,301.4,1465.7,301.5,1453.4,301.4z"
          />
          <path
            className="on-ee-2"
            stroke="var(--primary-color)"
            d="M1444.9,348.9c6.8-0.2,13.6-0.1,20.4-0.1c2.4,0.1,2,4.2-0.4,3.8c-6.5,0-13.1,0.1-19.6-0.1
        C1443.3,352.6,1443.1,349.3,1444.9,348.9z"
          />
        </svg>
      </div>
    </div>
  );
};

export default memo(AnimationPathSW);
