import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Image } from '@components';
import { employeeList } from '@content';
import './employee-view.scss';

const EmployeeItem = props => {
  const { link, image } = props;
  return (
    <a href={link} target="_blank">
      <div className="on-employee-item-container">
        <Image className="on-employee-item-image" filename={image} />
      </div>
    </a>
  );
};

EmployeeItem.propTypes = {
  link: PropTypes.string,
  image: PropTypes.string,
};

EmployeeItem.defaultProps = {
  link: '',
  image: '',
};

const EmployeeComponent = props => {
  const { title } = props;
  return (
    <div className="on-employee-view-container">
      <h2>{title}</h2>
      <div className="on-employee-view-items-container">
        {employeeList.map(item => (
          <EmployeeItem key={item.id} image={item.imageUrl} link={item.link} />
        ))}
      </div>
    </div>
  );
};

export default memo(EmployeeComponent);
