import React, { memo, useState, useEffect } from 'react';
import { SEO, Card, InputField, ExpandingButton, Switch, Spinner } from '@components';
import { LandingTopAnimated } from '@containers';

import './control-panel.scss';
import { Link } from 'gatsby';

const ControlPanel = () => {
  return (
    <div>
      <SEO title="Employee area" />
      <LandingTopAnimated className="on-cp-animated" />
      <div className="on-cp-container">
        <Card>
          <div className="on-cp">
            <div>
              <h1>Menu</h1>
              <div className="on-cp-item-container">
                <MenuItem text="COVID Survey" link="/covid" />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

const MenuItem = props => {
  const { text, link } = props;
  return (
    <Link to={`/employee${link}`} className="on-cp-item-link">
      {' '}
      <Card className="on-cp-item">{text}</Card>
    </Link>
  );
};

export default memo(ControlPanel);
