export const transitionTimeout = 300;

export const transitionStyles = {
  entering: {
    position: 'absolute',
    opacity: 0,
    transform: 'translateY(30px)'
  },
  entered: {
    transition: `all ${transitionTimeout}ms ease-in-out`,
    opacity: 1,
    transform: 'translateY(0px)'
  },
  exiting: {
    transition: `all ${transitionTimeout}ms ease-in-out`,
    opacity: 0,
    transform: 'translateY(-30px)'
  },
};
