import React, { memo } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import { Image } from '@components';

import './image-card.scss';

const ImageCard = props => {
  const { children, filename } = props;
  const { className, style, imageClassName, contentClassName } = props;

  return (
    <div className={cs('on-image-card-base', className)} style={style}>
      <Image className={cs("on-image-card-image", imageClassName)} filename={filename}></Image>
      <div className={cs("on-image-card-content", contentClassName)}>{children}</div>
    </div>
  );
};

ImageCard.PropTypes = {
  children: PropTypes.node,

  className: PropTypes.string,
  imageClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  style: PropTypes.object,
};

ImageCard.defaultProps = {
  children: null,

  imageClassName: '',
  contentClassName: '',
  className: '',
  style: {},
};

export default memo(ImageCard);
