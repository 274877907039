import React, { memo, useEffect } from 'react';
import { navigate } from '@reach/router';
import { useState } from 'react';
import { InputField, ExpandingButton, Card } from '@components';
import * as Authorization from '@services/auth';
import firebase from 'gatsby-plugin-firebase';

const Login = () => {
  const [fb, setFb] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [serverState, setServerState] = useState(false);

  useEffect(() => {
    setFb(firebase);

    if (Authorization.isLoggedIn()) {
      navigate('/employee');
    }
  }, []);

  const submit = e => {
    e.preventDefault();
    fb.auth()
      .signInWithEmailAndPassword(username, password)
      .then(result => {
        console.log(result);
        Authorization.setUser(result.user);
        navigate('/employee');
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState(true);
    fb.auth()
      .signInWithEmailAndPassword(form[0].value, form[1].value)
      .then(result => {
        console.log(result);
        Authorization.setUser(result.user);
        navigate('/employee/dashboard');
        setServerState(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Card className="on-normal on-no-animation-normal">
      <div className="on-contact-us-form">
        <div>
          <h1>Login</h1>
        </div>
        <div>
          <div>
            <form onSubmit={handleOnSubmit} className="form">
              <InputField text="Email" name="username" id="username" />
              <InputField text="Password" name="password" id="password" type="password" />
              <div className="on-contact-us-from-button">
                <ExpandingButton
                  type="submit"
                  buttonText="LOGIN"
                  disabled={serverState.submitting}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default memo(Login);
